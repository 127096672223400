import React, { useEffect } from 'react';
import moment from 'moment';
import ApplicationStatisticsContainer from '../../../state/containers/ApplicationStatisticsContainer';
import { TabPanel } from 'react-tabs';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import TabbedLayout from '../../../components/structure/TabbedLayout';
import ApplicationUserCharts from './ApplicationUserCharts';
import ApplicationUserAnalyticsContainer from '../../../state/containers/ApplicationUserAnalyticsContainer';
import ApplicationInteractionsCharts from './ApplicationInteractionsCharts';
import ApplicationInteractionAnalyticsContainer from '../../../state/containers/ApplicationInteractionAnalyticsContainer';
import ApplicationStatistics from './ApplicationStatistics';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import ApplicationMissAnalyticsContainer from '../../../state/containers/ApplicationMissAnalyticsContainer';
import ApplicationMissCharts from './ApplicationMissCharts';
import { DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import SessionAnalyticsWrapper from './SessionAnalyticsWrapper';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationLiveSessions from './ApplicationLiveSessions';
import SparkContainer from '../../../hooks/SparkContainer';
import Loader from '../../../components/general/Loader';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';

interface ApplicationMeasurementPageProps {
    applicationId: string
    userAnalyticsContainer: ApplicationUserAnalyticsContainer
    interactionAnalyticsContainer: ApplicationInteractionAnalyticsContainer
    missAnalyticsContainer: ApplicationMissAnalyticsContainer
    statisticsContainer: ApplicationStatisticsContainer
    ssmlContainer: SsmlContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    featureContainer: FeatureContainer
    interval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    history?: any
    envContainer: ApplicationEnvironmentsContainer
    application: ApplicationModel
    appsContainer: ApplicationContainer
}

const ApplicationMeasurementPage: React.FC<ApplicationMeasurementPageProps> = (props) => {

    const sparkContainer = SparkContainer.useContainer();

    const enableDetailedAnalytics = async () => {
        const envs = await props.envContainer.loadEnvironments(props.applicationId, true);
        if (envs.data) {
            if (!envs.data[0]?.detailedAnalyticsEnabled) {
                await props.appsContainer.updateDetailedAnalytics(props.applicationId, true);
            }
        }
    };

    useEffect(() => {
        if (sparkContainer.inSpark) {
            enableDetailedAnalytics();
        }
    }, [props.applicationId])

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = `/v/apps/${props.applicationId}/analytics/`;
        if (sparkContainer.inSpark) {
            if (index == 0) {
                props.history.push(urlStart + "sessions" + window.location.search)
            }
            if (index == 1) {
                props.history.push(urlStart + "topic-hits" + window.location.search)
            }
            if (index == 2) {
                props.history.push(urlStart + "topic-misses" + window.location.search)
            }
        } else {
            if (index == 0) {
                props.history.push(urlStart + "activity" + window.location.search)
            }
            if (index == 1) {
                props.history.push(urlStart + "users" + window.location.search)
            }
            if (index == 2) {
                props.history.push(urlStart + "sessions" + window.location.search)
            }
            if (index == 3) {
                props.history.push(urlStart + "misses" + window.location.search)
            }
            if (index == 4) {
                props.history.push(urlStart + "report" + window.location.search)
            }
            if (index == 5) {
                props.history.push(urlStart + "live")
            }
        }
    };

    const getSelectedIndex = () => {
        if (sparkContainer.inSpark) {
            if (location.href.indexOf("sessions") > -1) {
                return 0;
            }
            if (location.href.indexOf("topic-hits") > -1) {
                return 1;
            }
            if (location.href.indexOf("topic-misses") > -1) {
                return 2;
            }
        } else {
            if (location.href.indexOf("users") > -1) {
                return 1;
            }
            if (location.href.indexOf("sessions") > -1) {
                return 2;
            }
            if (location.href.indexOf("misses") > -1) {
                return 3;
            }
            if (location.href.indexOf("report") > -1) {
                return 4;
            }
            if (location.href.indexOf("live") > -1) {
                return 5;
            }
            return 0;
        }
    };

    const getInnerInterval = (startDate: string, endDate: string): AnalyticsInterval => {
        // rule of 3s.
        // if we are under 3 days, use hours **NOTE: now using days and hours if more then 1 day because Nivo groups the same x axis values together and renders them as 1
        // if we are under 3 months, use days
        // if we are under 3 years, use months
        // use years

        // get hours between dates
        const hoursBetween = moment(endDate, DATE_FORMAT).diff(moment(startDate, DATE_FORMAT), 'hours');
        if (hoursBetween <= 24) {
            return "hour";
        }
        if (hoursBetween <= (24 * 2)) {
            return "dayAndHour"
        }
        if (hoursBetween <= (24 * 3 * 30)) {
            return "day"
        }
        if (hoursBetween <= (24 * 3 * 365)) {
            return "month"
        }
        return "year"
    };

    const innerInterval = getInnerInterval(props.filter.startDate, props.filter.endDate);

    const renderSparkTabs = () => {
        return (
            <>
                <TabPanel>
                    <SessionAnalyticsWrapper
                        filter={props.filter}
                        interval={props.interval}
                        innerInterval={innerInterval}
                        ssmlContainer={props.ssmlContainer}
                        envContainer={props.envContainer}
                        history={props.history}
                        userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                        applicationId={props.applicationId} />
                </TabPanel>
                <TabPanel>
                    <ApplicationStatistics
                        showTopicHits={true}
                        application={props.application}
                        filter={props.filter}
                        interval={props.interval}
                        innerInterval={innerInterval}
                        featureContainer={props.featureContainer}
                        stateContainer={props.statisticsContainer}
                        applicationId={props.applicationId}
                        ssmlContainer={props.ssmlContainer}
                        envContainer={props.envContainer}
                        history={props.history}
                        userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                        statisticsContainer={props.statisticsContainer}
                    />
                </TabPanel>
                <TabPanel>
                    <ApplicationStatistics
                        showTopicMisses={true}
                        application={props.application}
                        filter={props.filter}
                        interval={props.interval}
                        innerInterval={innerInterval}
                        featureContainer={props.featureContainer}
                        stateContainer={props.statisticsContainer}
                        applicationId={props.applicationId}
                        ssmlContainer={props.ssmlContainer}
                        envContainer={props.envContainer}
                        history={props.history}
                        userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                        statisticsContainer={props.statisticsContainer}
                    />
                </TabPanel>
            </>
        )
    };

    const renderVoicifyTabs = () => {
        return (
            <>
                <TabPanel>
                    <ApplicationInteractionsCharts filter={props.filter} interval={props.interval} innerInterval={innerInterval} stateContainer={props.interactionAnalyticsContainer} applicationId={props.applicationId} />
                </TabPanel>
                <TabPanel>
                    <ApplicationUserCharts filter={props.filter} interval={props.interval} innerInterval={innerInterval} stateContainer={props.userAnalyticsContainer} applicationId={props.applicationId} />
                </TabPanel>
                <TabPanel>
                    <SessionAnalyticsWrapper
                        filter={props.filter}
                        interval={props.interval}
                        innerInterval={innerInterval}
                        ssmlContainer={props.ssmlContainer}
                        envContainer={props.envContainer}
                        history={props.history}
                        userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                        applicationId={props.applicationId} />
                </TabPanel>
                <TabPanel>
                    <ApplicationMissCharts filter={props.filter} interval={props.interval} innerInterval={innerInterval} stateContainer={props.missAnalyticsContainer} applicationId={props.applicationId} />
                </TabPanel>
                <TabPanel>
                    <ApplicationStatistics
                        application={props.application}
                        filter={props.filter}
                        interval={props.interval}
                        innerInterval={innerInterval}
                        featureContainer={props.featureContainer}
                        stateContainer={props.statisticsContainer}
                        applicationId={props.applicationId}
                        ssmlContainer={props.ssmlContainer}
                        envContainer={props.envContainer}
                        history={props.history}
                        userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                        statisticsContainer={props.statisticsContainer}
                    />
                </TabPanel>
                <TabPanel>
                    <ApplicationLiveSessions
                        history={props.history}
                        applicationId={props.applicationId} />
                </TabPanel>
            </>
        )
    }

    if (!props.application?.id?.length || !props.applicationId?.length) {
        return <Loader />
    }
    return (
        <ScrollablePanelBody>
            <TabbedLayout
                tabs={sparkContainer.inSpark ? ["Transcripts", "Topic Hits", "Topic Misses"] : ["Activity", "Users", "Sessions", "Misses", "Report", "Live"]}
                top
                selectedIndex={getSelectedIndex()}
                onTabSelected={handleTabSelection}
            >
                {sparkContainer.inSpark ? renderSparkTabs() : renderVoicifyTabs()}
            </TabbedLayout>
        </ScrollablePanelBody>
    )
}

export default ApplicationMeasurementPage;