import * as voicifyApi from '../../api';
import QuestionAnswerModel from '../../models/features/api/QuestionAnswers/QuestionAnswerModel';
import NewQuestionAnswerRequest from '../../models/features/api/QuestionAnswers/NewQuestionAnswerRequest';
import BulkQuestionAnswerUpdateRequest from '../../models/features/api/QuestionAnswers/BulkQuestionAnswerUpdateRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import { QuestionAnswerFeatureTypeId } from '../../constants/featureTypeIds';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { QuestionAnswerContentFormData } from '../../scenes/formScenes/QuestionAnswerForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import PhraseSlot from '../../models/interactionModel/PhraseSlot';

interface QuestionAnswerState extends ContentItemState<QuestionAnswerModel> {
}

export default class QuestionAnswerContainer extends GenericContentItemContainer<QuestionAnswerState, QuestionAnswerModel, NewQuestionAnswerRequest, BulkQuestionAnswerUpdateRequest, QuestionAnswerContentFormData> {


    // default state
    state = {
        contentItems: [] as QuestionAnswerModel[],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }


    mapModelToGenericContentItemModel = (model: QuestionAnswerModel) => ({
        ...model,
        hasAudio: model.responses.some(a => a.audio != null),
        hasVideo: model.responses.some(a => a.video != null),
        hasImage: model.responses.some(a => a.largeImage != null),
        hasBackgroundImage: model.responses.some(a => a.backgroundImage != null),
        featureTypeId: QuestionAnswerFeatureTypeId
    })

    mapFormDataToModel = (originalModel: QuestionAnswerModel, formData: QuestionAnswerContentFormData) => {
        const finalModel = {
            ...originalModel,
            title: formData.title,
            requiresParent: formData.requiresParent,
            isLive: formData.isLive,
            languages: formData.languages,
            webhookChainingType: formData.webhookChainingType,
            sensitiveConversationChange: formData.sensitiveConversationChange,
            questionSet: formData.questions.map(q => ({
                ...q,
                id: q.questionId,
                minimumConfidenceScore: formData.minimumConfidenceScore,
                requiredPhrases: formData.requiredPhrases
            })),
            responses: formData.responses,
        }
        return finalModel;
    };

    featureTypeId = QuestionAnswerFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getQuestionAnswersForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // not implemented
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getQuestionAnswersForAppFeature(applicationFeatureId);
    createPromise = (model: NewQuestionAnswerRequest) => voicifyApi.createQuestionAnswer(model);
    createStubPromise = (model: NewQuestionAnswerRequest) => voicifyApi.createQuestionAnswerStub(model);
    createFromModelPromise = (model: QuestionAnswerModel) => voicifyApi.createFullQuestionAnswer(model);
    updatePromise = (contentItemId: string, model: BulkQuestionAnswerUpdateRequest) => voicifyApi.bulkUpdateQuestionAnswer(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: BulkQuestionAnswerUpdateRequest) => voicifyApi.bulkUpdateQuestionAnswerStub(contentItemId, model);
    updateFromModelPromise = (model: QuestionAnswerModel) => voicifyApi.updateFullQuestionAnswer(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteQuestionAnswer(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleQuestionAnswerLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleQuestionAnswerSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyQuestionAnswer(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findQuestionAnswer(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getQuestionAnswerPublishHistory(contentItemId, environmentId, skip, take);

    getWebhooksPromise = (contentItemId: string) => voicifyApi.getQuestionAnswerWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeQuestionAnswerWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateQuestionAnswerWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addQuestionAnswerWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateQuestionAnswerWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
    getSimpleSampleFlows = (contentItem: QuestionAnswerModel, application?: ApplicationModel) => [{
        requests: contentItem.questionSet.map((q, index) => ({ content: q.content, highlights: [q.content], id: `q${index}` })),
        followUp: contentItem.responses[0]?.followUp,
        responses: contentItem.responses.map(a => ({
            content: a.content,
            highlights: [],
            id: a.content
        })),
        requestTitle: "User Says",
        responseTitle: "Device Response"
    }]
}
