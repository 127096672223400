import React from 'react';
import { css } from '@emotion/css';
import moment from 'moment';
import AnalyticsInterval from '../../models/analytics/AnalyticsInterval';
import { ocean_blue, cool_grey, dark_grey_blue_10, silver_three, silver_two, dark_grey_blue, color_text_light, color_shades_dark } from '../../constants/colors';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DATE_FORMAT } from '../../models/analytics/AnalyticsDateFormat';
const indicatorIcon = require('../../content/images/analytics-icons/date-toggle-indicator.svg');

interface TimeIntervalPickerProps {
    isExpanded: boolean
    startDate?: string
    endDate?: string
    onClick?: () => void
}
const TimeIntervalSelectorHeader: React.FC<TimeIntervalPickerProps> = (props) => (
    <div className={`${containerStyle} ac-analytics-timeframe-picker`} onClick={props.onClick}>
        <p className="time-label">Timeframe</p>
        <p className="time-selection">
            <span>{moment(props.startDate, DATE_FORMAT).format("MMM DD, YYYY")}</span>
            <span>-</span>
            <span>{moment(props.endDate, DATE_FORMAT).format("MMM DD, YYYY")}</span>
        </p>
        <img src={indicatorIcon} className={props.isExpanded ? 'up' : 'down'}/>
    </div>
)
const containerStyle = css`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    .time-label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${color_text_light};   
        padding: 0 16px 0 32px;
    }
    .time-selection {
        padding-left: 16px;
        font-size: 24px;
        line-height: 32px;
        border-left: 1px solid ${color_shades_dark};   
        >span {
            margin-right: 12px;
        }
    }

    >img {
        &.down {
            transform: rotate(180deg);
        }
    }
`

export default TimeIntervalSelectorHeader;