import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import InvalidResult from "../models/result/InvalidResult";
import { useEffect, useState } from "react";
import IResult from "../models/result/IResult";
import TopIntents from "../models/customAssistant/TopIntents";
import CallStatistics from "../models/customAssistant/CallStatistics";
import OrderTotalStatistics from "../models/analytics/OrderTotalStatisticsModel";
import TopLocationWithOrderCountCollection from "../models/analytics/LocationWithOrderCount";
import AnalyticsRequestFilter from "../models/analytics/AnalyticsRequestFilter";
import OrderPlacedEvent from "../models/analytics/OrderPlacedEvent";
import ConversationalSuccessEvent from "../models/analytics/ConversationalSuccessEvent";
import ItemAddedToOrderEvent from "../models/analytics/ItemAddedToOrderEvent";
import CallTransferEvents from "../models/analytics/CallTransferEvents";
import ImmediateCallTransferEvents from "../models/analytics/ImmediateCallTransferEvents";
import ApplicationsSearchResult from "../models/applications/api/ApplicationsSearchResult";
import OrderCompletionBreakDownPerAppCollection from "../models/analytics/OrderCompletionBreakDownPerAppCollection";
import CallTransferredRateModelCollection from "../models/analytics/CallTransferredRateModelCollection";
import ConversationalSuccessBreakdownCollection from "../models/analytics/ConversationalSuccessBreakdownCollection";
import CallImmediateTransferredRateModelCollection from "../models/analytics/CallImmediateTransferredRateModelCollection";
import AverageOrderTotalModelCollection from "../models/analytics/AverageOrderTotalModelCollection";
import SessionDateModel from "../models/analytics/api/SessionDateModel";

interface IOrgApplicationsPerformanceAnalytics {
    applicationId: string;
    OrderCompletionRate?: number;   // Optional since not all may have these
    ConversationalSuccessRate?: number;
    CallTransferredRate?: number;
    CallImmediateTransferredRate?: number;
    AverageOrderTotal?: number;
}
import { EXCLUDED_TOP_INTENTS } from "../constants/Intents";
import ListStat from "../models/customAssistant/ListStat";
import { number } from 'prop-types';

function useOrgAnalyticsContainer(props) {
    const [error, setError] = useState("");
    const [loadingTopIntents, setLoadingTopIntents] = useState(false);
    const [loadingOrdersPlaced, setLoadingOrdersPlaced] = useState(false);
    const [loadingCallStatistics, setLoadingCallStatistics] = useState(false);
    const [loadingConversationalSucess, setLoadingConversationalSuccess] = useState(false);
    const [loadingItemAddedToOrder, setLoadingItemAddedToOrder] = useState(false);
    const [loadingOrderTotalStatistics, setLoadingOrderTotalStatistics] = useState(false);
    const [loadingTopLocationWithHighestNumberOfOrders, setLoadingTopLocationWithHighestNumberOfOrders] = useState(false);
    const [loadingOrgAnalytics, setLoadingOrgAnalytics] = useState(false);
    const [loadingOrgApplicationsPerformanceAnalytics, setLoadOrgApplicationsPerformanceAnalytics] = useState(false);
    const [loadingCallTransferredEvents, setLoadingCallTransferredEvents] = useState(false);
    const [loadingImmediateCallTransferredEvents, setLoadingImmediateCallTransferredEvents] = useState(false);
    const [loadingSessionsOfAllApplications, setLoadingSessionsOfAllApplications] = useState(false)

    const [sessions, setSessions] = useState([] as SessionDateModel[]);
    const [allEventsCache, setAllEventsCache] = useState({});
    const [loadingSessionId, setLoadingSessionId] = useState("")
    const [callStatistics, setCallStatistics] = useState<CallStatistics>(null);
    const [orderPlacedEvents, setOrderPlacedEvents] = useState<OrderPlacedEvent[]>(null);
    const [topIntents, setTopIntents] = useState<ListStat[]>(null);
    const [conversationalSuccessEvents, setConversationalSuccessEvents] = useState<ConversationalSuccessEvent[]>(null);
    const [itemAddedConversationalSuccessEvents, setItemAddedConversationalSuccessEvents] = useState<ConversationalSuccessEvent[]>(null);
    const [itemAddedToOrderEvents, setItemAddedToOrderEvents] = useState<ItemAddedToOrderEvent[]>(null);
    const [callCompleteEvents, setCallCompleteEvents] = useState<any[]>(null);
    const [orderTotalStatistics, setOrderTotalStatistics] = useState<OrderTotalStatistics>(null);
    const [topLocationsWithNumberOfOrdersPlaced, setTopLocationsWithHighestNumberOfOrders] = useState<TopLocationWithOrderCountCollection>(null);

    const [callTransferredEvents, setCallTransferredEvents] = useState<CallTransferEvents[]>()
    const [immediateCallTransferredEvents, setImmediateCallTransferredEvents] = useState<ImmediateCallTransferEvents[]>()

    const [immediateTransfers, setImmediateTransfers] = useState(0);
    const [transfers, setTransfers] = useState(0);
    const [callTransferRate, setCallTransferRate] = useState<number>()
    const [immediateCallTransferRate, setImmediateCallTransferRate] = useState<number>()
    const [conversationalSuccessRate, setConversationalSuccessRate] = useState<number>()

    const [orderPlacedCount, setOrdersPlacedCount] = useState<number>(0);
    const [attemptedOrdersConversationalSuccessRate, setAttemptedOrdersConversationalSuccessRate] = useState<number>(null);
    const [orderPlacedRate, setOrderPlacedRate] = useState<number>(null);
    const [orderTransferRate, setOrderTransferRate] = useState<number>(null);
    const [orderTransfers, setOrderTransfers] = useState<number>(null);
    const [conversationalSuccessCount, setConversationalSuccessCount] = useState<number>(null);
    const [conversationalSuccessWithOrderAttemptedCount, setConversationalSuccessWithOrderAttemptedCount] = useState<number>(null);
    const [orderAttempts, setOrderAttempts] = useState<number>();
    const [orgApplicationsPerformanceAnalytics, setOrgApplicationsPerformanceAnalytics] = useState<IOrgApplicationsPerformanceAnalytics[]>();
    const [unsuccessfulOrderAttempts, setUnsuccessfulOrderAttempts] = useState<number>(0);
    const [noInteractionCount, setNoInteractionCount] = useState<number>(0);
    const [unsuccessfulButTransfered, setUnsuccessfulButTransfered] = useState<number>(0);


    const NUMBER_OF_TOP_LOCATIONS_WITH_HIGHEST_ORDERS = 3;
    const [unsuccessfulOrderSessionIds, setUnsuccessfulOrderSessionIds] = useState([]);
    const [ordersPlacedSessionIds, setOrdersPlacedSessionIds] = useState([]);
    const [successfulOrdersNotPlacedSessionIds, setSuccessfulOrdersNotPlacedSessionIds] = useState([]);
    const [orderTransferredSessionIds, setOrderTransferredSessionIds] = useState([]);
    const [noOrderSessionIds, setNoOrderSessionIds] = useState([]);
    const [noTransferUnsuccessfulSessionIds, setNoTransferUnsuccessfulSessionIds] = useState([]);
    const [transferUnsuccessfulSessionIds, setTransferUnsuccessfulSessionIds] = useState([]);
    const [conversationalSuccessSessionIds, setConversationalSuccessSessionIds] = useState([]);
    const [sessionIdCount, setSessionIdCount] = useState(0);
    const [reloadBool, setReloadBool] = useState(false);

    const NUMBER_OF_SESSIONS_TO_TAKE = 300;

    useEffect(() => {
        if (!itemAddedToOrderEvents || !orderPlacedEvents || !conversationalSuccessEvents || !callStatistics || !callCompleteEvents?.length || !sessions.length) return
        const allSessionIds = getUniqueSessionIds(sessions);
        const itemAddedToOrderSessionIds = getUniqueSessionIds(itemAddedToOrderEvents)
        const callTransferedSessionIds = getUniqueSessionIds(callTransferredEvents);

        const orderAttempts = itemAddedToOrderSessionIds.length;
        setOrderAttempts(orderAttempts)
        const placedSessionIds = getUniqueSessionIds(orderPlacedEvents).filter(id => itemAddedToOrderSessionIds.includes(id));
        setOrdersPlacedSessionIds(placedSessionIds);
        setOrdersPlacedCount(getUniqueSessionIds(orderPlacedEvents)?.length ?? 0);


        const conversationalSuccessEventsOverride = conversationalSuccessEvents.filter(x => allSessionIds.includes(x.sessionId));
        setConversationalSuccessEvents(conversationalSuccessEventsOverride);
        const conversationalSuccessEventSessions = getUniqueSessionIds(conversationalSuccessEventsOverride);
        setSessionIdCount(allSessionIds.length);
        const preliminaryUnsuccessList = [];
        const shownConversationalSuccessEvents = [];
        allSessionIds.forEach(sessionId => {
            if (conversationalSuccessEventSessions.includes(sessionId)) {
                shownConversationalSuccessEvents.push(sessionId);
            } else {
                preliminaryUnsuccessList.push(sessionId);
            }
        });
        setConversationalSuccessSessionIds(shownConversationalSuccessEvents);
        setConversationalSuccessCount(shownConversationalSuccessEvents.length);
        const finalUnsuccessList = [];
        const tUnsucessTransferIds = [];
        const usuccessOrderAttemptsIds = []
        if (preliminaryUnsuccessList.length > 0) {
            let unsuccessfulInteractions = preliminaryUnsuccessList.filter(sessionId => {
                const callComplete = callCompleteEvents.find(x => x.sessionId === sessionId);
                if (callComplete) {
                    if (callComplete.newTTSGenerations + callComplete.cachedTTSGenerations < 2) {
                        return false;
                    }
                }
                return true;
            });
            setNoInteractionCount(preliminaryUnsuccessList.length - unsuccessfulInteractions.length);

            unsuccessfulInteractions.forEach(sessionId => {
                if (callTransferedSessionIds.includes(sessionId)) {
                    tUnsucessTransferIds.push(sessionId);
                } else {
                    finalUnsuccessList.push(sessionId);
                }
                if (itemAddedToOrderSessionIds.includes(sessionId)) {
                    usuccessOrderAttemptsIds.push(sessionId);
                }
            });
            setUnsuccessfulOrderAttempts(usuccessOrderAttemptsIds.length);
        }
        setNoTransferUnsuccessfulSessionIds(finalUnsuccessList);
        setTransferUnsuccessfulSessionIds(tUnsucessTransferIds);
        setUnsuccessfulButTransfered(tUnsucessTransferIds.length);

        setCallTransferRate(callTransferedSessionIds.length / callStatistics.calls);
        setTransfers(callTransferedSessionIds.length)
        // union of call transferred and order attempted session ids
        const oTransferSessIds = callTransferedSessionIds.filter(x => itemAddedToOrderSessionIds.includes(x));
        setOrderTransferredSessionIds(oTransferSessIds);

        const immediateCallTransferedSessionIds = getUniqueSessionIds(immediateCallTransferredEvents);
        setImmediateCallTransferRate(immediateCallTransferedSessionIds.length / callStatistics.calls);
        setImmediateTransfers(immediateCallTransferedSessionIds.length)

        const successfulOrderNotPlacedSessionIds = itemAddedToOrderSessionIds.filter(x => !placedSessionIds.includes(x) && conversationalSuccessEventSessions.includes(x));
        setSuccessfulOrdersNotPlacedSessionIds(successfulOrderNotPlacedSessionIds);
        const noOrderSessions = sessions.filter(x => !itemAddedToOrderSessionIds.includes(x.sessionId));
        setNoOrderSessionIds(noOrderSessions.map(s => s.sessionId));
        if (!itemAddedToOrderSessionIds?.length) {
            setOrderPlacedRate(-1);
            setOrderTransferRate(-1);
            setOrderTransfers(-1);
        } else {
            setOrderPlacedRate(placedSessionIds.length / itemAddedToOrderSessionIds.length);
            const orderTransferedSessionIds = callTransferedSessionIds.filter(x => itemAddedToOrderSessionIds.includes(x));
            setOrderTransfers(orderTransferedSessionIds.length);
            setOrderTransferRate(orderTransferedSessionIds.length / itemAddedToOrderSessionIds.length);

            const allConversationalSuccessRate = shownConversationalSuccessEvents.length / (callStatistics.calls ?? 1);
            setConversationalSuccessRate(allConversationalSuccessRate)


            if (itemAddedToOrderSessionIds?.length > 0) {
                const orderAttemptedConversationalSuccess = itemAddedToOrderSessionIds.filter(id => placedSessionIds.includes(id) || shownConversationalSuccessEvents.includes(id));
                setConversationalSuccessWithOrderAttemptedCount(orderAttemptedConversationalSuccess.length);
                const itemAddedConversationalSuccessRate = orderAttemptedConversationalSuccess.length / orderAttempts;
                setAttemptedOrdersConversationalSuccessRate(itemAddedConversationalSuccessRate);
                setUnsuccessfulOrderSessionIds(itemAddedToOrderSessionIds.filter(x => !placedSessionIds.includes(x) && !shownConversationalSuccessEvents.includes(x)));
            }
        }

    }, [itemAddedToOrderEvents, orderPlacedEvents, callStatistics, callCompleteEvents, sessions, reloadBool, callTransferredEvents])

    const getOrgLevelCallStatistics = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<CallStatistics>> => {
        setLoadingCallStatistics(true);
        const result = await voicifyApi.getCallStatistics(organizationId, filter);
        setLoadingCallStatistics(false);
        if (result.resultType === "Ok") {
            setCallStatistics(result.data);
        }
        else {
            setError(result.errors?.[0]);
            return new InvalidResult<CallStatistics>(result.errors?.[0]);
        }
    }

    const getOrgItemAddedConversationalSuccessEvents = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ConversationalSuccessEvent>> => {
        const result = await voicifyApi.getItemAddedConversationalSuccessEvents(organizationId, filter);
        if (result.resultType === "Ok") {
            setItemAddedConversationalSuccessEvents(result.data);
        }
        else {
            setError(result.errors?.[0]);
            return new InvalidResult<ConversationalSuccessEvent>(result.errors?.[0]);
        }
    }

    const getOrgLevelTopIntents = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        setLoadingTopIntents(true);
        const result = await voicifyApi.getTopIntents(organizationId, filter);
        setLoadingTopIntents(false);
        if (result.resultType === "Ok") {
            const intents: TopIntents[] = [];
            for (const intent of result.data) {
                if (EXCLUDED_TOP_INTENTS.includes(intent.intent)) {
                    continue;
                } else {
                    intents.push(intent);
                }
            }
            const topThreeIntents = intents.sort((a, b) => b.count - a.count).slice(0, 3);
            const topThree = [];
            for (const intent of topThreeIntents) {
                // Add spaces in between capital letters and before punctuation
                const splitIntent = intent.intent.split(/(?=[A-Z.,:;!?-])/).join(" ");
                topThree.push({ name: splitIntent, count: intent.count.toLocaleString() })
            }
            setTopIntents(topThree);
        }
        else {
            setError(result.errors?.[0]);
        }
    }

    const getOrgLevelOrdersPlaced = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        setLoadingOrdersPlaced(true);
        const result = await voicifyApi.getOrderPlacedEvents(organizationId, filter);
        setLoadingOrdersPlaced(false);
        if (result.resultType === "Ok") {
            setOrderPlacedEvents(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    }

    const getOrgLevelConversationalSuccess = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        setLoadingConversationalSuccess(true);
        const result = await voicifyApi.getConversationalSuccessEvents(organizationId, filter);
        setLoadingConversationalSuccess(false);
        if (result.resultType === "Ok") {
            setConversationalSuccessEvents(result.data)
            setReloadBool(true);
        }
        else {
            setError(result.errors?.[0]);
        }
    }

    const getOrgLevelItemAddedToOrder = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        setLoadingItemAddedToOrder(true);
        const result = await voicifyApi.getItemAddedToOrderEvents(organizationId, filter);
        setLoadingItemAddedToOrder(false);
        if (result.resultType === "Ok") {
            setItemAddedToOrderEvents(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    }

    const getOrgLevelCallCompleteEvents = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        const result = await voicifyApi.getOrgCallCompleteEvents(organizationId, filter);
        if (result.resultType === "Ok") {
            setCallCompleteEvents(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    }



    const getOrgLevelOrderTotalStatistics = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        setLoadingOrderTotalStatistics(true);
        const result = await voicifyApi.getOrderTotalStatistics(organizationId, filter);
        setLoadingOrderTotalStatistics(false);
        if (result.resultType === "Ok") {
            setOrderTotalStatistics(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    }

    const getTopLocationsWithNumberOfOrdersPlaced = async (organizationId: string, filter: AnalyticsRequestFilter): Promise<void> => {
        setLoadingTopLocationWithHighestNumberOfOrders(true);
        const result = await voicifyApi.getTopLocationsWithNumberOfOrdersPlaced(organizationId, filter);
        setLoadingTopLocationWithHighestNumberOfOrders(false);
        if (result.resultType === "Ok") {
            setTopLocationsWithHighestNumberOfOrders(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    }

    const getUniqueSessionIds = (events: any[]) => {
        if (!events) { return [] }
        events = events.filter(x => x?.sessionId?.length);
        const grouped = events.reduce((acc, item) => {
            if (!acc[item.sessionId]) {
                acc[item.sessionId] = [];
            }
            acc[item.sessionId].push(item);
            return acc;
        }, {});
        return Object.keys(grouped);
    };

    const loadAllEventsForSession = async (sessionId: string, applicationId: string) => {
        if (sessionId in allEventsCache) {
            return allEventsCache[sessionId];
        } else {
            setLoadingSessionId(sessionId);
            const allEvents = await voicifyApi.getAllEventsForSession(applicationId, sessionId);
            if (allEvents.resultType === "Ok") {
                allEvents.data.forEach(e => {
                    if (e.eventDate) {
                        e.timestamp = new Date(e.eventDate).getTime()
                    }
                })
                // sort by timestampt
                allEvents.data.sort((a, b) => a.timestamp - b.timestamp);
                const newEventsCache = { ...allEventsCache };
                newEventsCache[sessionId] = allEvents.data;
                setAllEventsCache(newEventsCache);
            }
            setLoadingSessionId("");
            return allEvents;
        }
    };

    const getTransferredEvents = async (organizationId: string, filter: AnalyticsRequestFilter) => {
        setLoadingCallTransferredEvents(true);
        const result = await voicifyApi.getCallTransferredEvents(organizationId, filter);
        setLoadingCallTransferredEvents(false);
        if (result.resultType === "Ok") {
            setCallTransferredEvents(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    };
    const getImmediateCallTransferredEvents = async (organizationId: string, filter: AnalyticsRequestFilter) => {
        setLoadingImmediateCallTransferredEvents(true);
        const result = await voicifyApi.getImmedirateCallTransferredEvents(organizationId, filter);
        setLoadingImmediateCallTransferredEvents(false);
        if (result.resultType === "Ok") {
            setImmediateCallTransferredEvents(result.data)
        }
        else {
            setError(result.errors?.[0]);
        }
    };

    const getSessionsOfAllApplications = async (organizationId: string, filter: AnalyticsRequestFilter) => {
        setLoadingSessionsOfAllApplications(true)
        setSessions([])
        let skip = -1;
        let take = -1;
        let result = await voicifyApi.getSessionsByOrganizationId(organizationId, filter, skip, take)
        let tempSessions: SessionDateModel[] = []
        tempSessions = [...tempSessions, ...result.data]
        // while (result.resultType === "Ok" && result.data.length === take) {
        //     skip += take;
        //     result = await voicifyApi.getSessionsByOrganizationId(organizationId, filter, skip, take)
        //     tempSessions = [...tempSessions, ...result.data]
        // }
        if (result.resultType !== "Ok") {
            setError(result.errors[0]);
            setLoadingSessionsOfAllApplications(false)
            return;
        }
        setSessions(tempSessions)
        setLoadingSessionsOfAllApplications(false)
    }


    const loadOrgAnalytics = async (organizationId: string, filter: AnalyticsRequestFilter) => {
        setLoadingOrgAnalytics(true)
        setCallStatistics(null)
        setTopIntents(null)
        setOrdersPlacedCount(0)
        setConversationalSuccessRate(null)
        setOrderTotalStatistics(null)
        setTopLocationsWithHighestNumberOfOrders(null)
        setCallTransferredEvents(null)
        setImmediateCallTransferredEvents(null)
        setItemAddedConversationalSuccessEvents(null);
        try {
            await Promise.all([
                getSessionsOfAllApplications(organizationId, filter),
                getOrgLevelCallStatistics(organizationId, filter),
                getOrgLevelTopIntents(organizationId, filter),
                getOrgLevelOrdersPlaced(organizationId, filter),
                getOrgLevelItemAddedToOrder(organizationId, filter),
                getOrgLevelCallCompleteEvents(organizationId, filter),
                getOrgLevelConversationalSuccess(organizationId, filter),
                getOrgLevelOrderTotalStatistics(organizationId, filter),
                getTopLocationsWithNumberOfOrdersPlaced(organizationId, { ...filter, count: NUMBER_OF_TOP_LOCATIONS_WITH_HIGHEST_ORDERS }),
                getTransferredEvents(organizationId, filter),
                getImmediateCallTransferredEvents(organizationId, filter),
                getOrgItemAddedConversationalSuccessEvents(organizationId, filter)
            ])

        }
        catch (error) {
            setError(error)
        } finally {
            setLoadingOrgAnalytics(false)
        }

    };


    const loadOrgApplicationsPerformanceAnalytics = async (organizationId: string, filter: AnalyticsRequestFilter) => {
        setLoadOrgApplicationsPerformanceAnalytics(true)
        const tasks = []
        tasks.push(voicifyApi.getOrderCompletionBreakDownPerApp(organizationId, filter))
        tasks.push(voicifyApi.getConversationalSuccessBreakdownPerApp(organizationId, filter))
        tasks.push(voicifyApi.getCallTransferredBreakdownPerApp(organizationId, filter))
        tasks.push(voicifyApi.getCallImmediateTransferredBreakdownPerApp(organizationId, filter))
        tasks.push(voicifyApi.getAverageOrderTotalBreakdownPerApp(organizationId, filter))
        tasks.push(voicifyApi.searchForApplications(organizationId, 0, 50, "NameAscending", ""))

        const results = await Promise.all(tasks);
        const errorResults = results.filter(x => x.resultType !== "Ok");
        if (errorResults.length) {
            setError(errorResults[0]);
            return;
        }

        const orderCompletion: OrderCompletionBreakDownPerAppCollection = results[0].data;
        const conversationalSuccess: ConversationalSuccessBreakdownCollection = results[1].data;
        const callTransferred: CallTransferredRateModelCollection = results[2].data;
        const callImmediateTransferred: CallImmediateTransferredRateModelCollection = results[3].data;
        const averageOrderTotal: AverageOrderTotalModelCollection = results[4].data;
        const availableApplications: ApplicationsSearchResult = results[5].data;

        const dataMap = new Map();
        // Function to update or add data by applicationId
        const addDataToMap = (array, key, fieldName) => {
            availableApplications.applications.forEach(app => {
                const appId = app.id; // Use app.id from allApps as the key
                if (!dataMap.has(appId)) {
                    // If the app is not found in dataMap, add it with default values
                    dataMap.set(appId, {
                        applicationId: appId,
                        name: app.name,
                        orderCompletionRate: 0,
                        conversationalSuccessRate: 0,
                        callTransferredRate: 0,
                        callImmediateTransferredRate: 0,
                        averageOrderTotal: 0
                    });
                }
            });
            if (array)
                array.forEach((item: { applicationId: string }) => {
                    const appId = item.applicationId;
                    if (!dataMap.has(appId)) {
                        dataMap.set(appId, { applicationId: appId });
                    }
                    dataMap.get(appId)[fieldName] = item[key] || "0";
                });
        };

        addDataToMap(orderCompletion.applications, "orderCompletionRate", "orderCompletionRate");
        addDataToMap(conversationalSuccess.applications, "conversationalSuccessRate", "conversationalSuccessRate");
        addDataToMap(callTransferred.applications, "callTransferredRate", "callTransferredRate");
        addDataToMap(callImmediateTransferred.applications, "callImmediateTransferredRate", "callImmediateTransferredRate");
        addDataToMap(averageOrderTotal.applications, "averageOrderTotal", "averageOrderTotal");

        // Convert the map back to an array
        const data = Array.from(dataMap.values());
        setOrgApplicationsPerformanceAnalytics(data)
        setLoadOrgApplicationsPerformanceAnalytics(false)
    }

    const resetStats = () => {
        setCallStatistics(null);
        setOrdersPlacedCount(0);
        setConversationalSuccessRate(null);
        setTopIntents(null);
        setOrderTotalStatistics(null);
    };

    return {
        getOrgLevelCallStatistics,
        getOrgLevelTopIntents,
        getOrgLevelOrdersPlaced,
        getOrgLevelConversationalSuccess,
        getOrgLevelItemAddedToOrder,
        getOrgLevelOrderTotalStatistics,
        getTopLocationsWithNumberOfOrdersPlaced,
        getSessionsOfAllApplications,
        loadAllEventsForSession,
        sessions,

        loadOrgAnalytics,
        loadOrgApplicationsPerformanceAnalytics,
        loadingSessionId,
        resetStats,
        allEventsCache,
        orderAttempts,
        callStatistics,
        orderPlacedEvents,
        topIntents,
        conversationalSuccessEvents,
        itemAddedToOrderEvents,
        orderTotalStatistics,
        topLocationsWithNumberOfOrdersPlaced,
        orgApplicationsPerformanceAnalytics,

        orderPlacedRate,
        orderPlacedCount,
        orderTransferRate,
        orderTransfers,
        sessionIdCount,

        conversationalSuccessCount,
        conversationalSuccessWithOrderAttemptedCount,
        conversationalSuccessRate,
        attemptedOrdersConversationalSuccessRate,
        itemAddedConversationalSuccessEvents,
        noTransferUnsuccessfulSessionIds,

        transfers,
        immediateTransfers,
        callTransferRate,
        immediateCallTransferRate,

        unsuccessfulOrderSessionIds,
        ordersPlacedSessionIds,
        orderTransferredSessionIds,
        noOrderSessionIds,

        error,
        loadingTopIntents,
        loadingOrdersPlaced,
        loadingCallStatistics,
        loadingConversationalSucess,
        loadingItemAddedToOrder,
        loadingOrderTotalStatistics,
        loadingTopLocationWithHighestNumberOfOrders,
        loadingOrgAnalytics,
        loadingOrgApplicationsPerformanceAnalytics,
        loadingCallTransferredEvents,
        loadingImmediateCallTransferredEvents,
        loadingSessionsOfAllApplications,
        noInteractionCount,
        unsuccessfulOrderAttempts,
        unsuccessfulButTransfered,
        transferUnsuccessfulSessionIds,
        conversationalSuccessSessionIds,
        successfulOrdersNotPlacedSessionIds
    };

}
const OrgAnalyticsContainer = createContainer(useOrgAnalyticsContainer);
export default OrgAnalyticsContainer;
