import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import { isAbsolute } from 'path';
import AuthenticationResponse from '../../models/auth/api/AuthenticationResponse';
import { VOICIFY_ACCESS_TOKEN_STORE, VOICIFY_REFRESH_TOKEN_STORE } from '../../constants/Keys';
import WelcomeMessageModel from '../../models/features/api/Welcome/WelcomeMessageModel';
import NewWelcomeMessageRequest from '../../models/features/api/Welcome/NewWelcomeMessageRequest';
import IResult from '../../models/result/IResult';
import UpdateWelcomeMessageRequest from '../../models/features/api/Welcome/UpdateWelcomeMessageRequest';
import IContentWebhookContainer from '../definitions/IContentWebhookContainer';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import WebhookParameterValue from '../../models/webhooks/api/WebhookParameterValue';
import { addOrReplace } from '../../models/extensions';
import IGenericContentContainer from '../definitions/IGenericContentContainer';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import { WelcomeMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { EditWelcomeMessageFormData } from '../../scenes/formScenes/WelcomeMessageForm';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface WelcomeMessageState extends ContentItemState<WelcomeMessageModel> {
}

export default class WelcomeMessageContainer extends GenericContentItemContainer<WelcomeMessageState, WelcomeMessageModel, NewWelcomeMessageRequest, UpdateWelcomeMessageRequest, EditWelcomeMessageFormData>{
  
    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }

    mapModelToGenericContentItemModel = (model: WelcomeMessageModel) => ({
        ...model,
        featureTypeId: this.featureTypeId
    });
    mapFormDataToModel = (originalModel: WelcomeMessageModel, formData: EditWelcomeMessageFormData) => ({
        ...originalModel,
        webhookChainingType: formData.webhookChainingType,
        title: formData.title,
        isLive: formData.isLive,
        languages: formData.languages,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,

        // we null these values out so they don't conflict with the responses field for variations        
        smallImage: null,
        largeImage: null,
        backgroundImage: null,
        audio: null,
        video: null,
        content: null,
        reprompt: null,
        followUp: null,
        mediaResponseContainer: null,
        mediaResponseContainerId: null,
        displayTitleOverride: null,
        displayTextOverride: null,
        followUpId: null,
        responseId: null
    });

    featureTypeId = WelcomeMessagesFeatureTypeId;
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleWelcomeMessageLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleWelcomeMessageSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyWelcomeMessage(contentItemId);
    deletePromise = (contentItemId: string) => voicifyApi.deleteWelcomeMessage(contentItemId);
    updatePromise = (contentItemId: string, model: UpdateWelcomeMessageRequest) => voicifyApi.updateWelcomeMessage(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: any) => voicifyApi.updateWelcomeMessage(contentItemId, model);
    updateFromModelPromise = (model: WelcomeMessageModel) => voicifyApi.updateFullWelcomeMessage(model.id, model);
    createPromise = (model: NewWelcomeMessageRequest) => voicifyApi.addWelcomeMessage(model.applicationId, model);
    createStubPromise = (model: any) => voicifyApi.addWelcomeMessage(model.applicationId, model);
    createFromModelPromise = (model: WelcomeMessageModel) => voicifyApi.addFullWelcomeMessage(model);
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getWelcomeMessagesForAppFeature(applicationFeatureId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // welcome messages don't have modules
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getWelcomeMessagesForApp(applicationId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findWelcomeMessage(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getWelcomeMessagePublishHistory(contentItemId, environmentId, skip, take);

    getWebhooksPromise = (contentItemId: string) => voicifyApi.getWelcomeMessageWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeWelcomeMessageWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateWelcomeMessageWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addWelcomeMessageWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateWelcomeMessageWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);

    getSimpleSampleFlows = (contentItem: WelcomeMessageModel, application: ApplicationModel) => [{
        requests: [{ content: `Hello ${application.invocationPhrase}`, highlights: [], id: "1" },
        { content: `Open ${application.invocationPhrase}`, highlights: [], id: "2" },
        { content: `Start ${application.invocationPhrase}`, highlights: [], id: "3" },
        { content: `Talk to ${application.invocationPhrase}`, highlights: [], id: "4" }],
        followUp: contentItem.followUp,
        responses: [{
            content: contentItem.content,
            highlights: [],
            id: "10",
        }],
        requestTitle: "User Says",
        responseTitle: "Device Response"
    }];
}
