import * as voicifyApi from '../../api';
import LatestMessageModel from '../../models/features/api/LatestMessages/LatestMessageModel';
import NewLatestMessageRequest from '../../models/features/api/LatestMessages/NewLatestMessageRequest';
import BulkUpdateLatestMessageRequest from '../../models/features/api/LatestMessages/BulkUpdateLatestMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import { LatestMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { LatestMessageContentFormData } from '../../scenes/formScenes/LatestMessageForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import moment from 'moment';
import IResult from '../../models/result/IResult';
import WebhookInstnaceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface LatestMessageState extends ContentItemState<LatestMessageModel> {
}

export default class LatestMessageContainer extends GenericContentItemContainer<LatestMessageState, LatestMessageModel, NewLatestMessageRequest, BulkUpdateLatestMessageRequest, LatestMessageContentFormData> {
   
    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }


    mapModelToGenericContentItemModel = (model: LatestMessageModel) => ({
        ...model,
        hasAudio: model.responses.some(a => a.audio != null),
        hasVideo: model.responses.some(a => a.video != null),
        hasImage: model.responses.some(a => a.largeImage != null),
        hasBackgroundImage: model.responses.some(a => a.backgroundImage != null),
        featureTypeId: LatestMessagesFeatureTypeId
    })
    mapFormDataToModel = (originalModel: LatestMessageModel, formData: LatestMessageContentFormData) => ({
        ...originalModel,
        webhookChainingType: formData.webhookChainingType,
        title: formData.title,
        category: formData.category,
        requiresParent: formData.requiresParent,
        isLive: formData.isLive,
        languages: formData.languages,
        startDate: formData.startDate,
        endDate: formData.endDate,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,
    });


    featureTypeId = LatestMessagesFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getLatestMessagesForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // not implemented
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getLatestMessagesForAppFeature(applicationFeatureId);
    createPromise = (model: NewLatestMessageRequest) => voicifyApi.createLatestMessage(model);
    createStubPromise = (model: NewLatestMessageRequest) => voicifyApi.createLatestMessageStub(model);
    createFromModelPromise = (model: LatestMessageModel) => voicifyApi.createFullLatestMessage(model);
    updatePromise = (contentItemId: string, model: BulkUpdateLatestMessageRequest) => voicifyApi.bulkUpdateLatestMessage(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: BulkUpdateLatestMessageRequest) => voicifyApi.bulkUpdateLatestMessageStub(contentItemId, model);
    updateFromModelPromise = (model: LatestMessageModel) => voicifyApi.updateFullLatestMessage(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteLatestMessage(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleLatestMessageLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleLatestMessageSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyLatestMessage(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findLatestMessage(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getLatestMessagePublishHistory(contentItemId, environmentId, skip, take);


    getWebhooksPromise = (contentItemId: string) => voicifyApi.getLatestMessageWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeLatestMessageWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateLatestMessageWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addLatestMessageWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateLatestMessageWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
    getSimpleSampleFlows = (contentItem: LatestMessageModel, application?: ApplicationModel) => [{
        requests: [
            { content: `What's new with ${contentItem.category}?`, highlights: [], id: '1' },
            { content: `What's the latest from ${contentItem.category}?`, highlights: [], id: '2' },
            { content: `What's the daily message from ${contentItem.category}?`, highlights: [], id: '3' },
            { content: `What was the message from ${contentItem.category} on ${moment(contentItem.startDate).format('MMMM Do')}?`, highlights: [], id: '4' },
        ],
        followUp: contentItem.responses[0]?.followUp,
        responses: contentItem.responses.map(a => ({
            content: a.content,
            highlights: [],
            id: a.content,
        })),
        requestTitle: "User Says",
        responseTitle: "Device Response"
    }]
}
