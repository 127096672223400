import React from 'react';
import { css } from '@emotion/css';
import { color_colors_decline, color_colors_gold, color_colors_growth, color_colors_ocean, color_text_light } from '../../constants/colors';
import ConditionalTooltipWrapper from '../general/ConditionalTooltipWrapper';
import styled from '@emotion/styled';
import TopIntents from '../../models/customAssistant/TopIntents';
import { nb } from 'date-fns/locale';
const deltaDown = require('../../content/images/analytics-icons/delta-down.svg');
const deltaUp = require('../../content/images/analytics-icons/delta-up.svg');

interface TotalProps {
    title: string
    subtitle?: string
    value: string | number | string[]
    deltaValue?: number
    deltaPercentage?: number
    showValue?: boolean
    showPercentage?: boolean
    tooltip?: string
    icon?: string
    secondIcon?: string
    className?: string
    showValueAsPercent?: boolean
    subValue?: string
    highlight?: boolean
}

const TotalCard: React.FC<TotalProps> = (props) => (
    <div className={props.className ? `${props.className} ${containerStyle}` : containerStyle}>
        <ConditionalTooltipWrapper showTooltip={!!props.tooltip?.length} text={props.tooltip}>
            <div className={`total-item ${props.highlight && 'highlight'}`}>
                <p className="item-label">{props.title}</p>
                {props.subtitle && <p className="item-label">{props.subtitle}</p>}
                {props.icon &&
                    <IconWrapper>
                        <StyledIcon src={props.icon} />
                        {props.secondIcon && <StyledIcon src={props.secondIcon} />}
                    </IconWrapper>
                }
                <div className="item-values-container">
                    {Array.isArray(props.value) ? props.value.length > 1 && props.value.map((val, index) => (
                        <p key={index} className="item-value">{val}</p>
                    )) :
                        <p className='item-value'>{props.value}{props.showValueAsPercent && '%'}<span className="sub-value">{props.subValue}</span></p>
                    }
                    {!isNaN(props.deltaValue) && props.deltaValue !== null && (
                        <img src={props.deltaValue > 0 ? deltaUp : deltaDown} />
                    )}
                    {!isNaN(props.deltaValue) && props.showValue && <p className="item-delta">{Math.abs(props.deltaValue)}</p>}
                    {!isNaN(props.deltaValue) && props.showValue && props.showPercentage && <div className="item-separator" />}
                    {!isNaN(props.deltaValue) && props.showPercentage && <p className="item-delta">{Math.abs(props.deltaPercentage)}%</p>}
                </div>
            </div>
        </ConditionalTooltipWrapper>
    </div>
)

const StyledIcon = styled.img`
    margin-right: 8px;  
    height: 38px;
    width: 38px;  
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 16px;
`;

const containerStyle = css`
    height: 96px;
    flex: 1;
    font-family: Muli;
    border: 1px solid #CBCCD2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 32px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    .highlight {
        font-weight: bold;
        color: ${color_colors_ocean};
    }
    .total-item {
        flex: 1; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item-label {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${color_text_light};
        }
        .item-values-container {
            display: flex;
            align-items: center;
            img {
                margin-left: 8px;
                margin-right: 4px;
            }
            .item-value {
                font-size: 18px;
                line-height: 24px;
            }
            .sub-value {
                color: ${color_text_light};
                font-size: 14px;
                line-height: 20px;
                margin-left: 8px;
            }
            .item-delta {
                color: ${color_text_light};
                font-size: 14px;
                line-height: 20px;
            }
            .item-separator {
                height: 16px;
                width: 1px;
                background :${color_text_light};
                margin: 0 8px;
            }

        }
    }

`

export default TotalCard;