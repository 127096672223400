import React from 'react';
import { css } from '@emotion/css';
import Button from './Button';
import { breakpoint_small } from '../../constants/breakpoints';

const closeIcon = require('../../content/images/close-white.svg')

interface BackProps {
    onClick: () => void
}

const BackButton: React.FC<BackProps> = (props) => (
    <Button themes={['inverse']}  onClick={props.onClick} text="Close" icon={closeIcon} additionalClasses={backStyle}/>
);

const backStyle = css`
    width: 160px;
    ${breakpoint_small} {
        font-size: 14px;
        width: 100px;
        img {
            width: 18px;
        }
    }
`;

export default BackButton;