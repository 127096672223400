import React from 'react';
import { css } from '@emotion/css';
import ContentHitModel from '../../../models/analytics/api/ContentHitModel';
import TableCell from '../../../components/general/TableCell';
import { color_text_default, color_text_link, color_colors_ocean } from '../../../constants/colors';
import moment from 'moment';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import TopContentHitModel from '../../../models/analytics/api/TopContentHitModel';
import ReportTableHeader from '../../../components/analytics/ReportTableHeader';
import ReportTable from '../../../components/analytics/ReportTable';
import { getEditUrl, getSmallFeatureTypeIcon } from '../../../models/extensions';
import StatisticsModel from '../../../models/analytics/api/StatisticsModel';
import { DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import TotalCard from '../../../components/charts/TotalCard';
import { FallbackFeatureTypeId, SPARK_EXCLUDED_FEATURE_TYPE_IDS, WelcomeMessagesFeatureTypeId } from '../../../constants/featureTypeIds';
import styled from '@emotion/styled';
import SparkContainer from '../../../hooks/SparkContainer';
import Loader from '../../../components/general/Loader';
import ApplicationStatisticsContainer from '../../../state/containers/ApplicationStatisticsContainer';
import useIsMobile from '../../../hooks/UseIsMobile';

const phoneIconBlue = require('../../../content/images/spark/phone.svg');
const averageIconBlue = require('../../../content/images/spark/average.svg');
const questionIconBlue = require('../../../content/images/spark/question.svg');

interface ApplicationTopHitsTableProps {
    data: TopContentHitModel
    totals: StatisticsModel
    previousTotals: StatisticsModel
    featureContainer: FeatureContainer
    isLoading: boolean
    startDate: string
    endDate: string
    showValue: boolean
    showPercentage: boolean
    statisticsContainer: ApplicationStatisticsContainer
    filteredInteractionsCount: number
    aggregatedFeatureTypeInteractionsLoaded: boolean
    previousDates: { startDate: string, endDate: string }
    previousAggregatedFeatureTypeInteractionsLoaded: boolean
    previousInteractionsCount: number
}

const ApplicationTopHitsTable: React.FC<ApplicationTopHitsTableProps> = (props) => {
    const sparkContainer = SparkContainer.useContainer();
    const isMobile = useIsMobile();

    const deltaUsers = (props.totals?.totalUsers ?? 0) - (props.previousTotals?.totalUsers ?? 0);
    const deltaNewUsers = (props.totals?.totalNewUsers ?? 0) - (props.previousTotals?.totalNewUsers ?? 0);
    const deltaSessions = (props.totals?.totalSessions ?? 0) - (props.previousTotals?.totalSessions ?? 0);
    const deltaRequests = (props.filteredInteractionsCount ?? 0) - (props.previousTotals?.totalRequests ?? 0);
    const deltaInteractions = (props.filteredInteractionsCount ?? 0) - (props.previousInteractionsCount ?? 0);

    const timeframeLabel = `${moment(props.startDate, DATE_FORMAT).format("MMM DD, YYYY")} - ${moment(props.endDate, DATE_FORMAT).format("MMM DD, YYYY")}`;
    const numberOfDays = moment(props.endDate, DATE_FORMAT).diff(moment(props.startDate, DATE_FORMAT), 'days') + 1;

    // Spark: average calls per day
    const previousNumberOfDays = moment(props.previousDates.endDate, DATE_FORMAT).diff(moment(props.previousDates.startDate, DATE_FORMAT), 'days') + 1;
    const averageCallsPerDay = Math.round((props.totals?.totalSessions ?? 0) / numberOfDays);
    const previousAverageCallsPerDay = Math.round((props.previousTotals?.totalSessions ?? 0) / previousNumberOfDays);
    const deltaAverageCallsPerDay = averageCallsPerDay - previousAverageCallsPerDay;

    // Spark: average topic inquiries per call
    const averageTopicInquiriesPerCall = (props.filteredInteractionsCount ?? 0) / (props.totals?.totalSessions ?? 1) || 0;
    const previousAverageTopicInquiriesPerCall = (props.previousTotals?.totalRequests ?? 0) / (props.previousTotals?.totalSessions ?? 1) || 0;
    const deltaAverageTopicInquiriesPerCall = (props.previousTotals?.totalSessions ?? 0) === 0 ? 0 : averageTopicInquiriesPerCall - previousAverageTopicInquiriesPerCall;
    const deltaAverageTopicInquiriesPerCallPercentage = (previousAverageTopicInquiriesPerCall > 0) ? Math.round(deltaAverageTopicInquiriesPerCall / previousAverageTopicInquiriesPerCall * 100) : 0;

    const getExportableData = () => {
        const rows = [];
        rows.push([`"${timeframeLabel}"`, `Total Users: ${props.totals?.totalUsers ?? 0}`, `Total New Users: ${props.totals?.totalNewUsers ?? 0}`, `Total Sessions: ${props?.totals?.totalSessions ?? 0}`, `Total Interactions: ${props.totals?.totalSessions ?? 0}`])
        rows.push([]);
        rows.push(['Item name', 'Users', 'New Users', 'Sessions', 'Interactions']);
        props.data.items.forEach(item => {
            rows.push([item.title, item.userCount, item.newUserCount, item.sessionCount, item.count]);
        })
        return rows;
    };

    const sparkMobileColumns = [
        {
            id: "topicInquired",
            Header: <ReportTableHeader title="Topic Inquired" showPercentage={false} showValue={false} />,
            accessor: (item: ContentHitModel) => item,
            Cell: row => (
                <TableCell>
                    <img className="feature-type-icon" src={getSmallFeatureTypeIcon((row.value.featureTypeId == null || row.value.featureTypeId == "null") ? FallbackFeatureTypeId : row.value.featureTypeId)} />
                    <p className="ac-analytics-report-content-item">{row.value.title ?? "Deleted Topic"}</p>
                </TableCell>
            )
        },
        {
            id: "timesTopicInquired",
            Header: <ReportTableHeader title='Times Topic Inquired' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.count,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        }
    ]

    const sparkColumns = [
        {
            id: "topicInquired",
            Header: <ReportTableHeader title="Topic Inquired" showPercentage={false} showValue={false} />,
            accessor: (item: ContentHitModel) => item,
            Cell: row => (
                <TableCell>
                    <img className="feature-type-icon" src={getSmallFeatureTypeIcon((row.value.featureTypeId == null || row.value.featureTypeId == "null") ? FallbackFeatureTypeId : row.value.featureTypeId)} />
                    <p className="ac-analytics-report-content-item">{row.value.title ?? "Deleted Topic"}</p>
                </TableCell>
            )
        },
        {
            id: "callerCount",
            Header: <ReportTableHeader title='# Callers' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.userCount,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        },
        {
            id: "newCallerCount",
            Header: <ReportTableHeader title='# New Callers' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.newUserCount,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        },
        {
            id: "timesTopicInquired",
            Header: <ReportTableHeader title='Times Topic Inquired' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.count,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        }
    ];

    const columns = [
        {
            id: "title",
            Header: <ReportTableHeader title="Conversation Item" showPercentage={false} showValue={false} />,
            accessor: (item: ContentHitModel) => item,
            Cell: row => (
                <TableCell>
                    <img className="feature-type-icon" src={getSmallFeatureTypeIcon((row.value.featureTypeId == null || row.value.featureTypeId == "null") ? FallbackFeatureTypeId : row.value.featureTypeId)} />
                    <a className="ac-analytics-report-content-item" href={getEditUrl(row.value.contentId, row.value.applicationFeatureId, (row.value.featureTypeId == null || row.value.featureTypeId == "null") ? FallbackFeatureTypeId : row.value.featureTypeId) + 'analytics' + window.location.search} target="_blank">{row.value.title ?? "No Title"}</a>
                </TableCell>
            )
        },
        {
            id: "userCount",
            Header: <ReportTableHeader title='Users' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.userCount,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        },
        {
            id: "newUserCount",
            Header: <ReportTableHeader title='New Users' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.newUserCount,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        },
        {
            id: "sessionCount",
            Header: <ReportTableHeader title='Sessions' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.sessionCount,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        },
        {
            id: "count",
            Header: <ReportTableHeader title='Interactions' showValue={false} showPercentage={false} />,
            accessor: (item: ContentHitModel) => item.count,
            Cell: row => (
                <TableCell>
                    {row.value}
                </TableCell>
            )
        }
    ];

    const items = props.data?.items?.filter(i => i.featureTypeId && !i.featureTypeId?.startsWith("response"));

    const sparkItems = props.data?.items?.filter(i => !SPARK_EXCLUDED_FEATURE_TYPE_IDS.includes(i.featureTypeId));

    return (
        <div className={wrapperStyle}>
            {props.isLoading || (!sparkContainer.inSpark && !props.data) ? <div className="loader-container"><Loader /></div> :
                <>
                    {!sparkContainer.inSpark && <h3>App Summary</h3>}
                    <TotalsWrapper>
                        {sparkContainer.inSpark ?
                            <>
                                <StyledTotalCard
                                    title="Calls"
                                    value={props.totals?.totalSessions}
                                    deltaValue={deltaSessions}
                                    deltaPercentage={Math.round(deltaSessions / (props.previousTotals?.totalSessions > 0 ? props.previousTotals?.totalSessions : 1) * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                    icon={phoneIconBlue}
                                />
                                <StyledTotalCard
                                    title="Average Calls per Day"
                                    value={averageCallsPerDay}
                                    deltaValue={deltaAverageCallsPerDay}
                                    deltaPercentage={Math.round(deltaAverageCallsPerDay / (previousAverageCallsPerDay > 0 ? previousAverageCallsPerDay : 1) * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                    icon={averageIconBlue}
                                    secondIcon={phoneIconBlue}
                                />
                                <StyledTotalCard
                                    title="Total Topic Inquiries"
                                    value={props.filteredInteractionsCount}
                                    deltaValue={deltaInteractions}
                                    deltaPercentage={Math.round(deltaInteractions / props.filteredInteractionsCount * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                    icon={questionIconBlue}
                                />
                                <StyledTotalCard
                                    title="Average Topic Inquiries per Call"
                                    value={Math.round(Number(averageTopicInquiriesPerCall.toFixed(2)))}
                                    deltaValue={Math.round(Number(deltaAverageTopicInquiriesPerCall.toFixed(2)))}
                                    deltaPercentage={deltaAverageTopicInquiriesPerCallPercentage}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                    icon={averageIconBlue}
                                    secondIcon={questionIconBlue}
                                />
                            </>
                            :
                            <>
                                <TotalCard
                                    title="Users"
                                    value={props.totals?.totalUsers}
                                    deltaValue={deltaUsers}
                                    deltaPercentage={Math.round(deltaUsers / (props.previousTotals?.totalUsers > 0 ? props.previousTotals?.totalUsers : 1) * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                />
                                <TotalCard
                                    title="New Users"
                                    value={props.totals?.totalNewUsers ?? 0}
                                    deltaValue={deltaNewUsers}
                                    deltaPercentage={Math.round(deltaNewUsers / (props.previousTotals?.totalNewUsers > 0 ? props.previousTotals?.totalNewUsers : 1) * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                />
                                <TotalCard
                                    title="Sessions"
                                    value={props.totals?.totalSessions}
                                    deltaValue={deltaSessions}
                                    deltaPercentage={Math.round(deltaSessions / (props.previousTotals?.totalSessions > 0 ? props.previousTotals?.totalSessions : 1) * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                />
                                <TotalCard
                                    title="Interactions"
                                    value={props.totals?.totalRequests}
                                    deltaValue={deltaRequests}
                                    deltaPercentage={Math.round(deltaRequests / (props.previousTotals?.totalRequests > 0 ? props.previousTotals?.totalRequests : 1) * 100)}
                                    showValue={props.showValue}
                                    showPercentage={props.showPercentage}
                                />
                            </>
                        }
                    </TotalsWrapper>
                    {!sparkContainer.inSpark &&
                        <h3 className="ac-analytics-hits-table">By Content</h3>
                    }
                    {sparkContainer.inSpark ?
                        <ReportTable
                            className={containerStyle}
                            columns={isMobile ? sparkMobileColumns : sparkColumns}
                            data={sparkItems}
                            showPagination={false}
                            getExportableData={getExportableData}
                            exportName={`${props.startDate}-${props.endDate}_ContentHits`}
                        />
                        :
                        <ReportTable
                            className={containerStyle}
                            columns={columns}
                            data={items}
                            showPagination={false}
                            getExportableData={getExportableData}
                            exportName={`${props.startDate}-${props.endDate}_ContentHits`}
                        />
                    }
                </>}
        </div>
    );
}

const StyledTotalCard = styled(TotalCard)`
    padding: 32px;
    height: auto;
`;

const wrapperStyle = css`
.loader-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    .spinner > div {
        background: ${color_colors_ocean};
    }
}
>h3 {
    margin: 16px 32px;
}
`

const TotalsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 16px;
    padding-bottom: 16px;
`;

const containerStyle = css`
    .feature-type-icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
    }
    a {
        color: ${color_text_default};
        &:hover {
            color: ${color_text_link};
        }
    }
`

export default ApplicationTopHitsTable;