import * as voicifyApi from '../../api';
import HelpMessageModel from '../../models/features/api/Help/HelpMessageModel';
import NewHelpMessageRequest from '../../models/features/api/Help/NewHelpMessageRequest';
import UpdateHelpMessageRequest from '../../models/features/api/Help/UpdateHelpMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import { HelpMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { EditHelpMessageFormData } from '../../scenes/formScenes/HelpMessageForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface HelpMessageState extends ContentItemState<HelpMessageModel> {
}

export default class HelpMessageContainer extends GenericContentItemContainer<HelpMessageState, HelpMessageModel, NewHelpMessageRequest, UpdateHelpMessageRequest, EditHelpMessageFormData> {
    
    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }


    mapModelToGenericContentItemModel = (model: HelpMessageModel) => ({
        ...model,
        hasAudio: model.audio != null,
        hasVideo: model.video != null,
        hasImage: model.largeImage != null,
        hasBackgroundImage: model.backgroundImage != null,
        featureTypeId: HelpMessagesFeatureTypeId,
    })

    mapFormDataToModel = (originalModel: HelpMessageModel, formData: EditHelpMessageFormData) => ({
        ...originalModel, 
        webhookChainingType: formData.webhookChainingType,
        title: formData.title,
        isLive: formData.isLive,
        requiresParent: formData.requiresParent,
        languages: formData.languages,        
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,

        // we null these values out so they don't conflict with the responses field for variations        
        smallImage: null,
        largeImage: null,
        backgroundImage: null,
        audio: null,
        video: null,
        content: null,
        reprompt: null,
        followUp: null,
        mediaResponseContainer: null,
        mediaResponseContainerId: null,
        displayTitleOverride: null,
        displayTextOverride: null,
        followUpId: null,
        responseId: null
    });


    featureTypeId = HelpMessagesFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getHelpMessagesForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // not implemented
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getHelpMessagesForAppFeature(applicationFeatureId);
    createPromise = (model: NewHelpMessageRequest) => voicifyApi.addHelpMessage(model.applicationId, model);
    createStubPromise = (model: NewHelpMessageRequest) => voicifyApi.addHelpMessageStub(model);
    createFromModelPromise = (model: HelpMessageModel) => voicifyApi.addFullHelpMessage(model);
    updatePromise = (contentItemId: string, model: UpdateHelpMessageRequest) => voicifyApi.updateHelpMessage(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: UpdateHelpMessageRequest) => voicifyApi.updateHelpMessageStub(contentItemId, model);
    updateFromModelPromise = (model: HelpMessageModel) => voicifyApi.updateFullHelpMessage(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteHelpMessage(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleHelpMessageLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleHelpMessageSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyHelpMessage(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findHelpMessage(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getHelpMessagePublishHistory(contentItemId, environmentId, skip, take);


    getWebhooksPromise = (contentItemId: string) => voicifyApi.getHelpMessageWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeHelpMessageWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateHelpMessageWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addHelpMessageWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateHelpMessageWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
    getSimpleSampleFlows = (contentItem: HelpMessageModel, application?: ApplicationModel) =>[{
        requests: [{ content: "Help", highlights: [], id: "1" }, { content: "What can I do?", highlights: [], id: "2" }],
        followUp: contentItem.followUp,
        responses: [{
            content: contentItem.content,
            highlights: [],
            id: "10"
        }],
        requestTitle: "User Says",
        responseTitle: "Device Response"
    }]
}
