import React, { useState } from "react";
import { createContainer } from "unstated-next";
import * as voicifyApi from '../api';
import { TelephonyDeploymentResponse } from "../models/telephonyDeployment/TelephonyDeploymentResponse";
import TelephonyDeploymentRequest from "../models/telephonyDeployment/TelephonyDeploymentRequest";

function useTelephonyDeploymentContainer() {
    const [telephonyDeployments, setTelephonyDeployments] = useState([] as TelephonyDeploymentResponse[]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);

    const getTelephonyDeployments = async (currentAppId: string) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getTelephonyDeployments(currentAppId);
            if (result.resultType == "Ok") {
                setTelephonyDeployments(result.data);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
                setTelephonyDeployments([]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const createTelephonyDeployment = async (currentAppId: string, request: TelephonyDeploymentRequest) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.addTelephonyDeployment(currentAppId, request);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
            return error;
        }
    };

    const updateTelephonyDeployment = async (currentAppId: string, phoneNumber: string, request: TelephonyDeploymentRequest) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateTelephonyDeployment(currentAppId, phoneNumber, request);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const deleteTelephonyDeployment = async (currentAppId: string, phoneNumber: string) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.deleteTelephonyDeployment(currentAppId, phoneNumber);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        errors,
        clearErrors: () => setErrors([]),
        getTelephonyDeployments,
        telephonyDeployments,
        createTelephonyDeployment,
        updateTelephonyDeployment,
        deleteTelephonyDeployment
    };
}

const TelephonyDeploymentContainer = createContainer(useTelephonyDeploymentContainer);
export default TelephonyDeploymentContainer;
