import React from 'react';
import { css } from '@emotion/css';
import { silver_four, color_shades_darkest, color_shades_darker, color_shades_dark, color_colors_ocean, color_variants_main_bg, text_blue } from '../../../constants/colors';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { Link } from 'react-router-dom';
import MembershipModel from '../../../models/organizations/api/MembershipModel';
import UserAvatar from '../../../components/general/UserAvatar';
import IconCollectionItem from '../../../models/media/IconCollectionItem';
import IconCollectionAvatar from '../../../components/general/IconCollectionAvatar';
import InvitationModel from '../../../models/organizations/api/InvitationModel';
import OverflowMenu from '../../../components/general/overflowMenu/OverflowMenu';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import Button from '../../../components/general/Button';
import styled from '@emotion/styled';
const removeMember = require('../../../content/images/remove-member.svg');
const removeMemberDisabled = require('../../../content/images/remove-member-disabled.svg');
const resendMail = require('../../../content/images/resend-mail.svg');
const copyIcon = require('../../../content/images/copy-text.svg');

interface OrganizationMemberCardProps {
    member?: MembershipModel
    invitation?: InvitationModel
    updateRole?: (memberId: string, roleName: string) => void
    remove?: () => void
    resendInvitation?: () => void
    allowRoleChange?: boolean
    allowRemove?: boolean
}

interface OrganizationMemberState {
    showRoleDropdown: boolean,
    copyBtnText: string
}
class OrganizationMemberCard extends React.Component<OrganizationMemberCardProps, OrganizationMemberState> {
    constructor(props) {
        super(props);
        this.state = {
            showRoleDropdown: false,
            copyBtnText: 'Copy Link'
        }
    }
    copyLink() {
        const element = document.createElement('textarea');
        element.value = `${location.host}/acceptinvitation/${this.props.invitation?.id}`;
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        this.setState({
            ...this.state,
            copyBtnText: 'Copied'
        });
        setTimeout(() => {
            this.setState({
                ...this.state,
                copyBtnText: 'Copy Link'
            });
        }, 3000);
    }
    toggleRoleDropdown() {
        this.setState({
            ...this.state,
            showRoleDropdown: !this.state.showRoleDropdown
        });
    }
    selectRole(role: string) {
        if (this.props.allowRoleChange)
            this.props.updateRole(this.props.member.id, role);
    }
    render() {
        const member = this.props.member;
        const invitation = this.props.invitation;
        const firstName = member == null ? "New" : member.firstName;
        const lastName = member == null ? "User" : member.lastName;
        const email = member == null ? invitation?.email : member.email;
        const noImageText = member == null ? "?" : null;
        let memberRole = member?.isAdmin ? "Administrator" : "Contributor"
        if (member == null)
            memberRole = invitation?.isAdmin ? "Administrator" : "Contributor"

        const appMemberships = member?.applicationMemberships ?? [];
        return (
            <div className={cardStyle} key={member?.id ?? invitation?.id}>
                <div className={`${innerCard} ac-members-item`}>
                    <UserAvatar className="member-icon"
                        firstName={firstName}
                        lastName={lastName}
                        noImageText={noImageText}
                        src={member?.imageUrl}
                        size={56}
                        noBackground={true}
                        noShadow={true} />
                    <div className="spacer" />
                    <HorizontalSeparator />
                    <HorizontalContainer className="member-details">
                        <div className="center-vertically">
                            <div className="member-name">
                                {`${firstName} ${lastName}`}
                            </div>
                            <div className="member-email ac-member-email">
                                {email}
                            </div>
                        </div>
                    </HorizontalContainer>
                    <HorizontalSeparator />
                    {member == null ?
                        <>
                            <div className="resend">
                                <h5>Invite Sent</h5>
                                <Button text="Resend"
                                    themes={['small-flat']}
                                    onClick={() => this.props.resendInvitation()}
                                    rightIcon={resendMail}
                                    className={resendBtnClassName}
                                />
                            </div>


                            <RemoveCopyLinkWrapper>

                                <Button text="Remove"
                                    themes={['small-flat']}
                                    onClick={this.props.allowRoleChange ? this.props.remove : null}
                                    icon={this.props.allowRoleChange ? removeMember : removeMemberDisabled}
                                    className={`${this.props.allowRoleChange ? '' : 'disabled'} ${removeBtnClassName}`}
                                    disabled={!this.props.allowRoleChange}
                                />
                                <Button text={this.state.copyBtnText} themes={['small-flat']} onClick={this.copyLink.bind(this)} rightIcon={copyIcon} iconClassName={copyIconStyle} className={copyBtnStyle} />
                            </RemoveCopyLinkWrapper>
                        </>
                        :
                        <>
                            <div className="participant-in">PARTICIPANT IN</div>
                            <IconCollectionAvatar
                                className="app-collection ac-members-apps"
                                items={appMemberships.map(a => {
                                    var collectionItem: IconCollectionItem;
                                    collectionItem = {
                                        noImageText: `${a.applicationName == null ? "NA" : a.applicationName[0]}`,
                                        tooltipText: a.applicationName,
                                        imageUrl: a.applicationImageUrl
                                    }
                                    return collectionItem;
                                })}
                                limit={4} />
                            {this.props.allowRemove ?
                                <div className={`${removeMemberStyle}`} onClick={this.props.remove}>
                                    <img src={removeMember} />
                                    Remove
                                </div>
                                :
                                <div className={`${removeMemberStyle} ${this.props.allowRoleChange ? "" : 'disabled'}`} onClick={this.props.allowRoleChange ? this.props.remove : null}>
                                    <img src={this.props.allowRoleChange ? removeMember : removeMemberDisabled} />
                                    Remove
                                </div>
                            }
                        </>
                    }
                    <div className={memberRoleDropdownStyle}>
                        <div onClick={this.toggleRoleDropdown.bind(this)} className="current-role ac-member-role">
                            <div>{memberRole}</div>
                            {this.props.member != null && <ToggleIndicator className={chevronStyle} direction={this.state.showRoleDropdown ? "up" : "down"} />}
                        </div>
                        {(this.state.showRoleDropdown && this.props.member != null) &&
                            <OverflowMenu className={'overflow-menu'}
                                optionClassName={'overflow-option'}
                                onClose={this.toggleRoleDropdown.bind(this)}
                                onOptionSelected={this.selectRole.bind(this)}
                                options={[{ isDisabled: !this.props.allowRoleChange, label: "Administrator" }, { isDisabled: !this.props.allowRoleChange, label: "Contributor" }]} />
                        }
                    </div>

                </div>
            </div>
        )
    }
}

const removeMemberStyle = css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: ${color_colors_ocean};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 16px;
    img {
        margin-right: 4px;
    }
    &.disabled {
        cursor: initial;
        color: ${color_shades_dark};
        svg {
            fill: ${color_shades_dark};
        }
    }
    &.invitation {
        margin-top: 48px;
    }
`;

const memberRoleDropdownStyle = css`
    position: absolute;
    right: 16px;
    top: 12px;
    .current-role {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: ${color_shades_darkest};
        display: flex;
        flex-direction: row;
    }
    .overflow-menu {
        width: 120px;
        right: -14px;
    }
    .overflow-option {
        height: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }
`;

const chevronStyle = css`        
    margin-left: 8px;
    margin-top: 6px;
    margin-right: 6px;
`

const cardStyle = css`
    width: 250px;
    min-height: 210px;
    border-radius: 8px;
    background-color: white;
    border: solid 1px ${silver_four};
    margin: 48px 0px 48px 32px;
    position: relative;

    .member-icon {
        top: -28px;
        left: 24px;
        position: absolute;
    }

    .spacer {
        height: 20px;
    }

    .member-details {
        display: flex;
        flex-direction: column;
        margin: 12px 0px;
        min-height: 68px;
    }

    .center-vertically {
        margin: auto 0px;
    }

    .member-name {
        font-style: normal;
        font-stretch: normal;
        line-height: 24px; 
        letter-spacing: normal;
        color: ${color_shades_darkest};
        font-size: 18px;
    }

    .member-email {
        font-style: normal;
        font-stretch: normal;
        line-height: 20px; 
        letter-spacing: normal;
        color: ${color_shades_darkest};
        display: flex;
        font-size: 14px;
        margin: 0px;
    }

    .counts {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
        margin: 8px 0px;
        justify-content: center;
        img {
            margin-right: 4px;
            margin-bottom: 3px;
        }
    }

    .app-collection {
        margin-left: 12px;
        margin-top: 12px;
    }

    .participant-in {
        font-size: 12px;
        line-height: 16px;
        margin-top: 12px;
        color: ${color_shades_darker};
    }

    .invite-link {
        width: 202px;
        height: 32px;
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        margin: 12px 0px;
        box-shadow: inset 0px 1px 2px rgba(50, 70, 97, 0.1);
    }

    .direct-link {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
        display: flex;
        flex-direction: row;
        margin-top: 8px;
    }

    .resend {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-content: center;

        h5{
            font-style: italic;
            font-size: 14px;
            font-weight: 400;
            opacity: 0.8;
        }
    }

    .link {
        color: ${color_colors_ocean};
        cursor: pointer;
    }
`;

const innerCard = css`
    padding: 16px;
`;

const copyIconStyle = css`
    width: 16px;
`;

const copyBtnStyle = css`
    margin: 0;
    height: 20px;
`;

const RemoveCopyLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    font-size: 14px;
    margin-top: 40px;
`;

const removeBtnClassName = css`
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0;
    font-size: 14px;
    &.disabled {
        color: ${color_shades_dark};
        svg {
            fill: ${color_shades_dark};
        }
    }
`;

const resendBtnClassName = css`
    margin: 0px;
`;

export default OrganizationMemberCard;