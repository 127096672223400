import HistogramModel from "../../models/analytics/HistogramModel";
import { Container } from "unstated";
import CardinalityAggregate from "../../models/analytics/api/CardinalityAggregate";
import BreakdownModel from "../../models/analytics/BreakdownModel";
import AveragesModel from "../../models/analytics/AveragesModel";
import moment from "moment";
import DeltaPeriod from "../../models/analytics/DeltaPeriod";
import { DATE_FORMAT } from "../../models/analytics/AnalyticsDateFormat";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";

export interface AnalyticsState {
    isLoading?: boolean
    currentDisplayOption?: string
    sparkDisplayOption?: string
    currentDeltaPeriod?: DeltaPeriod
    currentDeltaType?: string
    errors?: string[]
}
export default abstract class GenericAnalyticsContainer<TState extends AnalyticsState> extends Container<TState> {

    handleDisplayOptionChange(option) {
        this.setState({
            ...this.state,
            currentDisplayOption: option
        })
    }
    handleSparkDisplayOptionChange(option) {
        this.setState({
            ...this.state,
            sparkDisplayOption: option
        })
    }
    handleDeltaPeriodChange(option) {
        return this.setState({
            ...this.state,
            currentDeltaPeriod: option
        })
    }
    handleDeltaTypeChange(option) {
        this.setState({
            ...this.state,
            currentDeltaType: option
        })
    }
    getPreviousDates(startDate, endDate) {
        if (this.state.currentDeltaPeriod == "Previous Period") {
            const start = moment(startDate, DATE_FORMAT);
            const end = moment(endDate, DATE_FORMAT);
            const hoursDiff = end.diff(start, 'hours');
            return {
                startDate: start.subtract(hoursDiff, 'hours').format(DATE_FORMAT),
                endDate: end.subtract(hoursDiff, 'hours').format(DATE_FORMAT)
            }
        }
        if (this.state.currentDeltaPeriod == "Same Time Last Year") {
            return {
                startDate: moment(startDate, DATE_FORMAT).subtract(1, 'year').format(DATE_FORMAT),
                endDate: moment(endDate, DATE_FORMAT).subtract(1, 'year').format(DATE_FORMAT)
            }
        }

        return null;
    }
    getDaysBetween(startDate, endDate, enforcePast?: boolean) {
        const start = moment(startDate, DATE_FORMAT);
        const end = moment(endDate, DATE_FORMAT);
        const today = moment();
        const isEndFuture = end.isAfter(today);
        const daysDiff = ((isEndFuture && enforcePast) ? today : end).diff(start, 'days') + 1; // add one to be inclusive of start date
        if (daysDiff < 1) // we can get 0 if we are in the same day, but we want to assert the calculation to 1
            return 1;
        return daysDiff
    }

    buildCacheKey(id: string, filter: AnalyticsRequestFilter, additionalKey?: string) {
        return id + JSON.stringify(filter) + (additionalKey ?? '')
    }

    buildBreakdownKey(breakdown: BreakdownModel) {
        if(breakdown.key)
            return breakdown.key;
        return this.buildCacheKey(breakdown.id, breakdown.filter, breakdown.type);
    }

    buildHistogramKey(histogram: HistogramModel) {
        if(histogram.key)
            return histogram.key;
        return this.buildCacheKey(histogram.id, histogram.filter)
    }

    buildAverageKey(average: AveragesModel) {
        if(average.key)
            return average.key;
        return this.buildCacheKey(average.id, average.filter);
    }


    abstract displayOptions: string[]

    protected getPercentage(count: number, agg: CardinalityAggregate[]) {
        return count / agg.reduce((a, b) => (a + b.count), 0) * 100
    }

    protected setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        })
    }

}