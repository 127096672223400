import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { MenuItemOption } from "../models/nlp/menu/MenuItemOption";
import MenuItemModel from "../models/nlp/menu/MenuItemModel";
import { MenuItemOptionGroup } from "../models/nlp/menu/MenuItemOptionGroup";
import { MenuItemSizeGroup } from "../models/nlp/menu/MenuItemSizeGroup";
import * as voicifyApi from "../api";
import ApplicationNlpMenuContainer from "./ApplicationNlpMenuContainer";
import { MenuItemSize } from "../models/nlp/menu/MenuItemSize";
import UpdateMenuRequest from "../models/nlp/menu/UpdateMenuRequest";
import { SyncMenusRequest } from "../models/nlp/menu/SyncMenusRequest";
import ApplicationModel from "../models/applications/api/ApplicationModel";
import { POSNames } from "../models/nlp/PosNames";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import IResult from "../models/result/IResult";
import EditedMenuItemModel from "../models/nlp/menu/EditedMenuItemModel";
import InteractionModelContainer from "./InteractionModelContainer";
import MenuSyncContainer from "./MenuSyncContainer";


interface Value {
    original: string
    current: string
}

export interface Diff {
    key: string
    value: Value
}

export interface PendingUpdate {
    menuItemId: string
    menuItemName: string
    diffs: Diff[]
}

interface MatchedItems {
    updatedItem: MenuItemModel
    diffs: DiffDetails[]
}

interface DiffDetails {
    key: string,
    diff: string,
    whatsChanged?: string,
    newValue: string | boolean
    originalValue: string | boolean
}

function useMenuContainer() {
    const [liveMenuItems, setLiveMenuItems] = useState<MenuItemModel[]>([]);
    const [fetchingMenu, setFetchingMenu] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState<MenuItemModel[]>([]);
    const [menuItem, setMenuItem] = useState<MenuItemModel>(null);
    const [originalMenuItem, setOriginalMenuItem] = useState<MenuItemModel>(null)
    const [categories, setCategories] = useState<string[]>([]);
    const [activeEditId, setActiveEditId] = useState("");
    const [isMenuItemEdited, setIsMenuItemEdited] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [loadingPOSMenuSync, setLoadingPOSMenuSync] = useState<boolean>(false);
    const [ladingPublishAllChanges, setLoadingPublishAllChanges] = useState<boolean>(false);
    const [matchingItemsWithSameOptionGroupOrSizeGroup, setmatchingItemsWithSameOptionGroupOrSizeGroup] = useState<MatchedItems[]>();
    const [showAskToUpdateOtherItemsModal, setShowAskToUpdateOtherItemsModal] = useState<boolean>(false);
    const [itemsToNotAugment, setItemsToNotAugment] = useState<string[]>([]);
    const [pendingMenuItemUpdates, setPendingMenuItemUpdates] = useState<PendingUpdate[]>([]);
    const [sizeName, setSizeName] = useState("");
    const [optionGroupsToAugment, setOptionGroupsToAugment] = useState<{ menuItemId: string, optionGroupRefId: string }[]>([]);
    const [path, setPath] = useState<{
        text: string,
        pathLength: number,
        selectedOption: MenuItemOption,
        selectedOptionGroup: MenuItemOptionGroup,
        selectedSizeGroup: MenuItemSizeGroup,
        selectedSize: MenuItemSize
    }[]>([] as any);

    const menuSyncContainer = MenuSyncContainer.useContainer();

    const [isLoadingMenu, setIsLoadingMenu] = useState(false);
    const [menuItemAugmentStatus, setMenuItemAugmentStatus] = useState<"success" | "loading" | "fail" | null>(null);
    const applicationNlpMenuContainer = ApplicationNlpMenuContainer.useContainer();
    const interactionModelContainer = InteractionModelContainer.useContainer();

    async function retrieveEntireMenu(applicationId: string): Promise<IResult<MenuItemModel[]>> {
        var menuItemResult = await voicifyApi.getVoicifyMenuItems(applicationId);
        const checkOptionGroup = (group: MenuItemOptionGroup) => {
            if (!group.referenceId?.length) {
                group.referenceId = uuidv4();
            }
            for (const option of group.options) {
                if (option?.optionGroups?.length) {
                    for (const subGroup of option.optionGroups) {
                        checkOptionGroup(subGroup);
                    }
                }
            }
        }
        const checkSizeGroup = (group: MenuItemSizeGroup) => {
            if (group?.sizes?.length) {
                for (const size of group.sizes) {
                    if (size?.optionGroups?.length) {
                        for (const subGroup of size.optionGroups) {
                            checkOptionGroup(subGroup);
                        }
                    }
                }
            }
        }
        // ensure all option groups have reference ids
        if (menuItemResult?.data?.length) {
            for (const item of menuItemResult.data) {
                if (item.optionGroups?.length) {
                    for (const group of item.optionGroups) {
                        checkOptionGroup(group);
                    }
                }
                if (item.sizeGroups?.length) {
                    for (const group of item.sizeGroups) {
                        checkSizeGroup(group);
                    }
                }
            }
        }
        return menuItemResult;
    }

    useEffect(() => {
        if (!menuItems?.length) {
            setPendingMenuItemUpdates([]);
            return;
        }
        if (!liveMenuItems?.length) {
            setPendingMenuItemUpdates([]);
            return;
        }
        let updates = [];
        for (const item of menuItems) {
            const liveItem = liveMenuItems.find(i => i.id === item.id);
            if (item.isDisabled) {
                if (liveItem) {
                    updates.push({ menuItemId: item.id, menuItemName: item.originalName, diffs: [] });
                }
                continue;
            }
            if (!liveItem) {
                updates.push({ menuItemId: item.id, menuItemName: item.originalName, diffs: [] });
                continue;
            }
            // identify properties that are different between live item and item
            const copy1 = JSON.parse(JSON.stringify(item));
            const copy2 = JSON.parse(JSON.stringify(liveItem));
            copy1.applicationId = "";
            copy2.applicationId = "";
            const diffs = findChangesInMenuItems(copy1, copy2, true);
            if (diffs.length) {
                updates.push({ menuItemId: item.id, menuItemName: item.originalName, diffs });
            }
        }
        setPendingMenuItemUpdates(updates);

    }, [menuItems, liveMenuItems])

    async function getLiveMenu(applicationid: string) {
        try {
            const menuResponse = await voicifyApi.getVoicifyMenuItems(applicationid);
            if (menuResponse.resultType === "Ok" && menuResponse.data.length > 0) {
                setLiveMenuItems([...menuResponse.data])
            }
        } catch {
        }
    }
    async function getMenu(applicationId: string) {
        try {
            setIsLoadingMenu(true);
            const menuResponse = await retrieveEntireMenu(applicationId)
            if (menuResponse.errors && menuResponse.errors.length > 0) {
                setErrors(menuResponse.errors)
            }
            if (menuResponse.resultType === "Ok" && menuResponse.data.length > 0) {
                setMenuItems([...menuResponse.data])

                const categoriesSet: Set<string> = new Set<string>()
                categoriesSet.add("All")
                for (const item of menuResponse.data) {
                    if (typeof item.attributes?.data?.category !== "undefined") {
                        categoriesSet.add(item.attributes?.data.category)
                    }
                }
                if (categoriesSet.size === 1) { categoriesSet.delete("All") }
                setCategories(Array.from(categoriesSet.values()))
            }
            setIsLoadingMenu(false);
        } catch (error) {
            setIsLoadingMenu(false);
            setErrors([error])
        }
    }

    async function getMenuItem(applicationId: string, menuItemId: string) {
        try {
            setIsLoadingMenu(true);
            const menuResponse = await voicifyApi.getVoicifyMenuItems(applicationId, menuItemId);
            if (menuResponse.errors && menuResponse.errors.length > 0) {
                setErrors(menuResponse.errors);
                setIsLoadingMenu(false);
            }
            if (menuResponse.resultType === "Ok" && menuResponse.data.length > 0) {
                setMenuItem({ ...menuResponse.data[0] })
                setOriginalMenuItem(JSON.parse(JSON.stringify(menuResponse.data[0])))
                setIsLoadingMenu(false);
                return menuResponse.data[0]
            }
            setIsLoadingMenu(false);
        } catch (error) {
            setIsLoadingMenu(false);
            setErrors([error])
        }
    }

    function resetMenuItemSelections() {
        setActiveEditId(null);
        setIsMenuItemEdited(false);
        setPath([]);
    }

    function updateMenuItem(menuItem: MenuItemModel, changedObj: MenuItemModel | MenuItemModel["attributes"] | MenuItemOptionGroup, changedProperty: string,
        persistentPropertiesOverride?: { changedObj: MenuItemModel | MenuItemModel["attributes"] | MenuItemOptionGroup, changedProperty: string }) {
        //do not allow updates during the augment process
        if (menuItemAugmentStatus) {
            return;
        }
        // the data dictionary can't have persistent properties. add the data dictionary to persistent properties instead via persistent properties overide
        if (persistentPropertiesOverride) {
            if (!persistentPropertiesOverride.changedObj.persistentProperties?.length) {
                persistentPropertiesOverride.changedObj.persistentProperties = [];
            }
            if (!persistentPropertiesOverride.changedObj.persistentProperties.includes(persistentPropertiesOverride.changedProperty) && persistentPropertiesOverride.changedProperty?.length) {
                persistentPropertiesOverride.changedObj.persistentProperties.push(persistentPropertiesOverride.changedProperty)
            }
        }
        else {
            if (!changedObj.persistentProperties?.length) {
                changedObj.persistentProperties = [];
            }
            if (!changedObj.persistentProperties.includes(changedProperty) && changedProperty?.length) {
                changedObj.persistentProperties.push(changedProperty)
            }
        }

        setIsMenuItemEdited(true);
        setMenuItem({ ...menuItem })
    }

    async function updateMenu(updatedItem: MenuItemModel, applicationId: string) {
        await applicationNlpMenuContainer.updateVoicifyMenuItem(applicationId, updatedItem);
    }

    function handleDisableEnableCategory(categoryIndexToUpdate: number, enableDisable: boolean, applicationId: string) {

        const filteredItems = menuItems.filter(
            ({ attributes }) => attributes?.data?.category === categories[categoryIndexToUpdate]
        );

        const updatedMenuItems = menuItems.map(item => {
            const matchingItem = filteredItems.find(filteredItem => filteredItem.id === item.id);

            //Since "All" is the first Category, it needs to go through all the items
            if (categoryIndexToUpdate === 0) {
                if ((item.isDisabled ?? false) !== enableDisable) { //Only call the API if isDisabled is changed
                    const result = { ...item, isDisabled: enableDisable };
                    updateMenu(result, applicationId);
                    return result;
                }
                return item;
            } else {
                if (matchingItem && (item.isDisabled ?? false) !== enableDisable) { //Only call the API if isDisabled is changed
                    const result = { ...item, isDisabled: enableDisable };
                    updateMenu(result, applicationId);
                    return result;
                }
                return item;
            }


        });

        setMenuItems(updatedMenuItems);
        setShowConfirmationModal(false);

    }

    async function syncMenuWithPOS(application: ApplicationModel) {
        setLoadingPOSMenuSync(true)
        const request: UpdateMenuRequest = {
            locationId: application.posLocationId,
            posName: application.posName as POSNames,
            manageMenuApplicationId: application.manageMenuApplicationId,
            liveApplicationId: application.id,
            updateLiveApplication: false,
            skipEntityExapnsion: true,
            skipMenuAugmentation: true
        }
        const result = await voicifyApi.initiateMenuUpdate(request)
        if (result?.resultType !== "Ok") {
            setErrors(result.errors)
        }
        setLoadingPOSMenuSync(false)
        setShowConfirmationModal(false)
    }

    async function publishAllChanges(application: ApplicationModel) {
        setLoadingPublishAllChanges(true);
        // generate a guid
        const processId = uuidv4();
        const request: SyncMenusRequest = {
            menuConfiguration: {
                primaryLocation: {
                    manageMenuApplicationId: application.manageMenuApplicationId,
                    manageMenuApplicationName: application.name,
                    liveApplicationId: application.id,
                    liveApplicationName: application.name,
                }
            },
            processId: processId
        }
        const result = await voicifyApi.syncMenus(request)

        if (result?.resultType !== "Ok") {
            setErrors(result.errors)
        }
        menuSyncContainer.startMenuSync(application.id, processId);
        setLoadingPublishAllChanges(false)
        setShowConfirmationModal(false)
    }

    const findOptionGroupAndSizeGroupsNeedAugment = (menuItemToUse: MenuItemModel, diffKeys: { key: string, diff: string }[]) => {
        const optionGroupsThatNeedReAugment = []
        const sizeGroupsThatNeedReAugment = []
        for (const diff of diffKeys) {
            const key = diff.key; //optionGroups.1.isDisabled
            const path = key?.split(".");

            let isOptionGroup = false;
            let isSizeGroup = false;
            let isOption = false;
            let isSize = false;

            //get the last optionGroup or sizeGroup
            for (const property of path.slice().reverse()) {//slice so that it does not mutate the original
                if (property === "optionGroups") {
                    isOptionGroup = true;
                    break;
                }
                else if (property === "sizeGroups") {
                    isSizeGroup = true;
                    break;
                } else if (property === "sizes") {
                    isSize = true;
                    break;
                } else if (property === "options") {
                    isOption = true;
                    break;
                }
            }

            let numberOfStepsBack = -3;
            if (key.includes("numberOfRequiredOptions")) {
                numberOfStepsBack = -1;
            }
            const mainGroupPath = path.slice(0, numberOfStepsBack) //-3 to go back to the main group eg. "optionGroups".1.isDisabled
            let changedGroup = getItemByPath(menuItemToUse, mainGroupPath, diff.diff) ?? menuItemToUse["sizeGroups"][0]?.sizes.find(s => normalizeName(s.name) === normalizeName(sizeName))?.optionGroups[0]
            if (changedGroup && key.includes("numberOfRequiredOptions")) {
                if (isOptionGroup) {
                    optionGroupsThatNeedReAugment.push({ changedGroup, index: mainGroupPath.slice(-1) });
                }
                if (isSizeGroup && typeof mainGroupPath.slice(-1)[0] === "string") {
                    sizeGroupsThatNeedReAugment.push({ changedGroup, index: parseInt(mainGroupPath.slice(-1)[0]) });
                }
            }
            if (changedGroup && (key.includes("colloquialName") || key.includes("isDisabled"))) {
                if (isOption) {
                    optionGroupsThatNeedReAugment.push({ changedGroup, index: mainGroupPath.slice(-1) });
                }
                if ((isSizeGroup || isSize) && typeof mainGroupPath.slice(-1)[0] === "string") {
                    sizeGroupsThatNeedReAugment.push({ changedGroup, index: parseInt(mainGroupPath.slice(-1)[0]) });
                }
            }
        }
        return {
            optionGroupsThatNeedReAugment,
            sizeGroupsThatNeedReAugment
        }
    }

    /**
     * get the clean/required data needed for augmenting the menu item
     * @returns null if the menu item does not require augment
     */
    function getCleanedMenuItemForAugment() {
        let menuItemToAugment: {
            itemId: string,
            optionGroupReferenceIdsToAugment: string[],
            sizeGroupIndeciesToAugment: number[]
        } | null = null;
        const diffs = findChangesInMenuItems(originalMenuItem, menuItem);

        const { optionGroupsThatNeedReAugment, sizeGroupsThatNeedReAugment } = findOptionGroupAndSizeGroupsNeedAugment(menuItem, diffs)

        const optionGroupReferenceIds = optionGroupsThatNeedReAugment.map(obj => obj.changedGroup.referenceId).filter(item => item);
        const sizeGroupIndecies = sizeGroupsThatNeedReAugment.map(obj => obj.index);

        //is a reaugment needed
        if (optionGroupReferenceIds.length > 0 || sizeGroupIndecies.length > 0) {
            menuItemToAugment = {
                itemId: menuItem.id,
                optionGroupReferenceIdsToAugment: [...new Set(optionGroupReferenceIds)],
                sizeGroupIndeciesToAugment: sizeGroupIndecies?.length ? [0] : []
            };

        }
        return menuItemToAugment
    }

    function getItemByPath(obj: any, path: string[], matchName: string): any {
        let item = obj;
        for (let i = 0; i < path.length; i++) {
            const pathI = path[i];
            if (i !== 0 && path[i - 1] === "optionGroups") {
                if (matchName) {
                    item = item?.find(i => normalizeName(i.name) === normalizeName(matchName))
                } else {
                    item = item?.find(i => i.referenceId === pathI);
                }

            } else if (i !== 0 && path[i - 1] === "sizeGroups") {
                if (item?.length) {
                    item = item[0];
                } else {
                    item = null;
                }
            } else if (i !== 0 && path[i - 1] === "options") {
                item = item?.find(i => i.id === pathI);
            } else if (i !== 0 && path[i - 1] === "sizes") {
                item = item?.find(i => i.id === pathI);
            } else {
                if (item && (pathI in item || Number(pathI) in item)) {
                    item = item[isNaN(Number(pathI)) ? pathI : Number(pathI)]
                }

            }

        }
        return item;
    }

    function findChangesInMenuItems(original: MenuItemModel = originalMenuItem, current: MenuItemModel = menuItem, skipIfOriginalDisabled = false): any {
        let differences: Record<string, any> = {};
        function findDifferences(obj1: any, obj2: any, path: string[] = []) {
            const isDisabledCase = (skipIfOriginalDisabled && obj1?.isDisabled);
            if (isDisabledCase) return;
            // If the objects are the same, no need to record a difference.
            if (_.isEqual(obj1, obj2)) return;
            if (_.isArray(obj1) && _.isArray(obj2)) {
                let lastKey = "";
                let optionGroups = false;
                let sizeGroups = false;
                let optionsSizes = false;
                if (path?.length) {
                    lastKey = path[path.length - 1];
                    if (lastKey === "optionGroups") {
                        optionGroups = true;
                    } else if (lastKey === "sizeGroups") {
                        sizeGroups = true;
                    } else if (lastKey === "options" || lastKey === "sizes") {
                        optionsSizes = true;
                    }
                }
                if (optionGroups) {
                    const allIds = new Set([...obj1.map(o => o.referenceId), ...obj2.map(o => o.referenceId)]);
                    allIds.forEach(key => {
                        if (!key?.includes("editId")) {
                            const o1 = obj1.find(o1 => o1.referenceId === key);
                            const o2 = obj2.find(o2 => o2.referenceId === key);
                            findDifferences(o1, o2, [...path, key]);
                        }
                    });
                } if (sizeGroups) {
                    const sizeGroup1 = obj1?.length ? obj1[0] : null;
                    const sizeGroup2 = obj2?.length ? obj2[0] : null;
                    findDifferences(sizeGroup1, sizeGroup2, [...path, "sizeGroup"]);
                } else if (optionsSizes) {
                    const allIds = new Set([...obj1.map(o => o.id), ...obj2.map(o => o.id)]);
                    allIds.forEach(key => {
                        if (!key.includes("editId")) {
                            const o1 = obj1.find(o1 => o1.id === key);
                            const o2 = obj2.find(o2 => o2.id === key);
                            findDifferences(o1, o2, [...path, key]);
                        }
                    });
                } else {
                    // we need to order arrays by id if we have it, then name, then reference id, then original name
                    let sorted1 = obj1?.filter(item => !skipIfOriginalDisabled || !item?.isDisabled);
                    sorted1 = sorted1.sort((a, b) => {
                        if (a?.id?.length) {
                            return a.id > b.id ? 1 : -1;
                        } else if (a?.originalName?.length) {
                            return a.originalName > b.originalName ? 1 : -1;
                        } else if (a?.referenceId?.length) {
                            return a.referenceId > b.referenceId ? 1 : -1;
                        } else if (a?.name?.length) {
                            return a.name > b.name ? 1 : -1;
                        }
                        return 1;
                    });
                    const sorted2 = obj2?.sort((a, b) => {
                        if (a?.id?.length) {
                            return a.id > b.id ? 1 : -1;
                        } else if (a?.originalName?.length) {
                            return a.originalName > b.originalName ? 1 : -1;
                        } else if (a?.referenceId?.length) {
                            return a.referenceId > b.referenceId ? 1 : -1;
                        } else if (a?.name?.length) {
                            return a.name > b.name ? 1 : -1;
                        }
                        return 1;
                    });
                    const allKeys = new Set([...Object.keys(sorted1), ...Object.keys(sorted2)]);
                    allKeys.forEach(key => {
                        if (!key.includes("editId"))
                            findDifferences(sorted1[key], sorted2[key], [...path, key]);
                    });
                }
            } else if (_.isObject(obj1) && _.isObject(obj2)) {
                const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
                allKeys.forEach(key => {
                    if (!key.includes("editId"))
                        findDifferences(obj1[key], obj2[key], [...path, key]);
                });
            }
            else {
                differences[path.join('.')] = { original: obj1, current: obj2 };
            }
        }

        findDifferences(original, current);
        return Object.entries(differences).map(([k, v]) => ({
            key: k,
            value: v
        }))
    }

    interface RequiredItemsInDiff {
        key: string,
        value: {
            current: string | boolean | undefined, original: string | boolean
        }
    }
    const handleItemNameDescriptionCategoryChange = (modified: string, foundMatchedItems: MatchedItems[], diff: RequiredItemsInDiff) => {
        let existingItem = foundMatchedItems.find(t => t.updatedItem.id === menuItem.id)
        if (existingItem) {
            existingItem.diffs.push({
                key: diff.key,
                diff: modified,
                newValue: diff.value?.current ?? false,
                originalValue: diff.value?.original ?? false,
            })
        } else {
            const data = {
                updatedItem: menuItem, //It is already updated
                diffs: [{
                    key: diff.key,
                    diff: modified,
                    newValue: diff.value?.current ?? false,
                    originalValue: diff.value?.original ?? false,
                }]
            }
            foundMatchedItems.push(data)
        }

    }

    const normalizeName = (name: string) => name.trim().toLowerCase();
    const deepEqual = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    /**
     * Finds the matching items based on the current changes.
     * @param applicationId 
     */
    async function askToUpdateOtherItems(applicationId: string): Promise<MatchedItems[]> {

        const diffs = findChangesInMenuItems(originalMenuItem, menuItem)
        const prompts = ["initialPromptOverride",
            "simpleInitialPromptOverride",
            "numberedInitialPromptOverride",
            "promptOverride", "colloquialName", "isDisabled"]

        setFetchingMenu(true);
        let entireMenu: MenuItemModel[];
        const result = await retrieveEntireMenu(applicationId)
        if (result.resultType === "Ok") {
            entireMenu = JSON.parse(JSON.stringify(result.data))
        }
        setFetchingMenu(false);
        const formattedMenuItems: EditedMenuItemModel[] = entireMenu.map((menuItem) => {
            return { menuItem: menuItem }
        });
        const foundMatchedItems: MatchedItems[] = [];

        const loadedGroups = interactionModelContainer.loadGroups(formattedMenuItems)
        for (const diff of diffs) {
            if (diff.key.includes("persistentProperties") || diff.key.includes("editId")) {
                continue
            }
            //If an option inside the sizeGroups is changed:
            let sizeName = "";
            let sizeGroupIdInKey = "";
            if (diff.key.includes("sizeGroups") && diff.key.includes("optionGroups") && diff.key.split(".")[0] === "sizeGroups") {
                sizeGroupIdInKey = diff.key.split(".").slice(0, 4)[3]; //Get the size Id from the key
                sizeName = menuItem.sizeGroups[0].sizes.find(size => size.id === sizeGroupIdInKey).name;
                setSizeName(sizeName) //The sizeName is used in the augmentation process to find the optionGroup
                diff.key = diff.key.split(".").slice(4).join('.'); //Remove sizeGroups.sizes and change the it to optionGroups.<refID>.options.<refId>.isDisabled.
            }
            const optionGroupOrSizeGroupKey = diff.key.split(".")// optionGroups.<refID>.options.<refID>.isDisabled ||    optionGroups.<refID>.options.<refID>.optionGroups.<refID>.options.<refID>
            const mainPropertyKey = optionGroupOrSizeGroupKey[0] //        ↑ look here
            const editedIndex = optionGroupOrSizeGroupKey[3]
            const propertyToUpdate = optionGroupOrSizeGroupKey[optionGroupOrSizeGroupKey.length - 1]

            let editedOptionGroupName: string

            let editedSizeGroupPrompt: string
            let editedSizeGroupName: string

            let modified: string
            let originalOptionName = ""
            let modifiedPath: string[] = [];
            let currentGroup: MenuItemModel | MenuItemOptionGroup | MenuItemOption = menuItem
            let currentOptionGroup: any
            if (mainPropertyKey === "colloquialName" || mainPropertyKey === "attributes") {

                switch (mainPropertyKey) {
                    case "colloquialName":
                        handleItemNameDescriptionCategoryChange("Item Referred to As", foundMatchedItems, diff);
                        break;

                    case "attributes": //attributes.data.description
                        let text: string
                        if (optionGroupOrSizeGroupKey[1] === "data" && optionGroupOrSizeGroupKey[2] === "category") {
                            text = "Category"
                        }
                        else if (optionGroupOrSizeGroupKey[1] === "description") {
                            text = "Description"
                        } else {
                            break;
                        }
                        handleItemNameDescriptionCategoryChange(text, foundMatchedItems, diff);
                        break;
                }

                continue;
            }

            //Finding the changes made:
            for (let i = 0; i < optionGroupOrSizeGroupKey.length; i++) {
                const key = optionGroupOrSizeGroupKey[i];
                if (key === "optionGroups" && currentGroup && 'optionGroups' in currentGroup) {
                    const groupIndex = optionGroupOrSizeGroupKey[++i]; // Get the index of the optionGroup
                    //If there is a sizeGroupId key, it means that an optionGroup is edited in a sizeGroup
                    if (sizeGroupIdInKey !== "" && "sizeGroups" in currentGroup) {
                        currentGroup = currentGroup.sizeGroups[0].sizes?.find(og => og?.id === sizeGroupIdInKey).optionGroups?.find(og => og?.referenceId === groupIndex) as MenuItemOptionGroup;
                    } else {
                        currentGroup = currentGroup.optionGroups?.find(og => og?.referenceId === groupIndex) as MenuItemOptionGroup;
                    }
                    currentOptionGroup = currentGroup;
                    editedOptionGroupName = currentGroup?.name ?? currentGroup?.referenceId;
                    modifiedPath.push(currentGroup?.name ?? currentGroup?.referenceId); // Append the name of the optionGroup
                } else if (key === "options" && currentGroup && 'options' in currentGroup) {
                    const optionIndex = optionGroupOrSizeGroupKey[++i]; // Get the index of the option
                    currentGroup = currentGroup.options?.find(o => o.id === optionIndex) as MenuItemOption;
                    modifiedPath.push(currentGroup.name); // Append the name of the option
                    originalOptionName = currentGroup.name;
                } else if (key === "promptIfNoSelections") { //eg: key optionGroups.0.options.1.promptIfNoSelections
                    modifiedPath.push(propertyToUpdate);
                } else if (key === "numberOfRequiredOptions") {
                    modifiedPath.push(propertyToUpdate);
                } else if (key === "isDisabled") {
                    modifiedPath.push(propertyToUpdate);
                }
            }

            let valueToUpdate
            if (mainPropertyKey === "optionGroups" || diff.key.includes("options")) {
                modified = modifiedPath.join(" - ")
                if (currentGroup) {
                    valueToUpdate = currentGroup[propertyToUpdate];
                }
            } else if (mainPropertyKey === "sizeGroups") {
                editedSizeGroupPrompt = menuItem.sizeGroups[0]?.promptOverride
                modified = `${editedSizeGroupPrompt}`
                valueToUpdate = menuItem.sizeGroups[0][propertyToUpdate]
                if (editedIndex) {
                    editedSizeGroupName = menuItem.sizeGroups[0]?.sizes?.find(s => s.id === editedIndex)?.name
                    modified = `${editedSizeGroupPrompt} -  ${editedSizeGroupName}`
                    valueToUpdate = menuItem.sizeGroups[0].sizes?.find(s => s.id === editedIndex)[propertyToUpdate]
                }
            }
            if (mainPropertyKey === "sizeGroups" && !diff.key.includes("optionGroups")) {
                entireMenu.forEach(item => {
                    for (const sizeGroup of item.sizeGroups) {
                        if (sizeGroup.promptOverride === editedSizeGroupPrompt) {
                            let existingItem = foundMatchedItems.find(t => t.updatedItem.id === item.id)
                            let originalValue: string | boolean
                            if (editedIndex) {
                                const targetSize = sizeGroup.sizes.find(s =>
                                    s.name.toLowerCase() === menuItem.sizeGroups[0].sizes?.find(s => s.id === editedIndex).name.toLowerCase());
                                if (targetSize) {
                                    originalValue = targetSize[propertyToUpdate]
                                    targetSize[propertyToUpdate] = valueToUpdate
                                    targetSize.persistentProperties = menuItem.sizeGroups[0].sizes?.find(s => s.id === editedIndex)?.persistentProperties
                                }
                            } else {
                                if (propertyToUpdate === "isDisabled") {
                                    originalValue = sizeGroup[propertyToUpdate]
                                    sizeGroup[propertyToUpdate] = valueToUpdate
                                }
                            }

                            if (existingItem) {
                                const targetSizeExisting = existingItem.updatedItem.sizeGroups.find(s => s.promptOverride === editedSizeGroupPrompt)
                                originalValue = targetSizeExisting[propertyToUpdate]
                                targetSizeExisting[propertyToUpdate] = valueToUpdate
                                targetSizeExisting.persistentProperties = menuItem.sizeGroups[0].sizes?.find(s => s.id === editedIndex)?.persistentProperties
                                if (originalValue === valueToUpdate) {
                                    break;
                                }
                                existingItem.diffs.push({
                                    key: diff.key,
                                    diff: modified,
                                    newValue: diff.value?.current ?? false,
                                    originalValue: originalValue ?? false,
                                })
                            } else {
                                if (originalValue === valueToUpdate) {
                                    break;
                                }
                                const data = {
                                    updatedItem: item,
                                    diffs: [{
                                        key: diff.key,
                                        diff: modified,
                                        newValue: diff.value?.current ?? false,
                                        originalValue: originalValue ?? false,
                                    }]
                                }
                                foundMatchedItems.push(data)
                            }
                        }

                    }
                })
            } else {
                //Find the optionGroups by edited optionGroup name
                const matches = loadedGroups.uniqueGroups.filter(uniqueGroup => {
                    if ("optionGroup" in uniqueGroup) {

                        if (uniqueGroup.optionGroup.name.toLowerCase() === editedOptionGroupName?.toLowerCase()) {
                            //If prompt is changed in the advanced settings. The number of options in the optionGroup has to match. The names should also match
                            if (!diff.key.includes("options") && prompts.includes(propertyToUpdate)) {
                                if (currentOptionGroup.options.length === uniqueGroup.optionGroup.options.length) {
                                    const sortedArr1 = currentOptionGroup.options.sort((a: MenuItemOption, b: MenuItemOption) => normalizeName(a.name).localeCompare(normalizeName(b.name)));
                                    const sortedArr2 = uniqueGroup.optionGroup.options.sort((a: MenuItemOption, b: MenuItemOption) => normalizeName(a.name).localeCompare(normalizeName(b.name)));
                                    for (let i = 0; i < sortedArr1.length; i++) {
                                        if (normalizeName(sortedArr1[i].name) !== normalizeName(sortedArr2[i].name)) {
                                            return false;
                                        }
                                    }
                                    return true
                                } else {
                                    return false
                                }
                            }

                            //If there is a name match
                            if (uniqueGroup.optionGroup.options.some(option => option.name.toLowerCase() === originalOptionName.toLowerCase())) {
                                return true
                                //If the optionGroup is disabled, promptIfNoSelections or numberOfRequiredOptions are changed:
                            }
                            else if ((modifiedPath[modifiedPath.length - 1] === "isDisabled" || modifiedPath[modifiedPath.length - 1] === "promptIfNoSelections" || modifiedPath[modifiedPath.length - 1] === "numberOfRequiredOptions")) {
                                return true
                            }
                        }
                    }
                    return false
                })
                //Find the items with matches optionGroup
                let matchingItemsIndexes: string[][] = []
                for (const match of matches) {
                    const foundItem = loadedGroups.matchingGroups.find(obj => match.editId in obj)?.[match.editId]
                    if (foundItem) {
                        matchingItemsIndexes.push(foundItem)
                    }
                }
                let itemOriginalValue = { value: "", id: "", persistentProperties: currentGroup?.persistentProperties }
                for (const itemIndexes of matchingItemsIndexes) {
                    let existingItems = foundMatchedItems.filter(it => itemIndexes.includes(it.updatedItem.id))
                    if (existingItems.length > 0) {//If item already exists, only update the specific property and push new data to diffs
                        for (const existingItem of existingItems) {
                            let whatsChanged = buildWhatsChanged(sizeGroupIdInKey, sizeName, editedOptionGroupName, originalOptionName);
                            let optionGroupToUse = getOptionGroupToUse(existingItem.updatedItem, sizeGroupIdInKey, sizeName)

                            if (optionGroupToUse === null) {
                                continue
                            }
                            itemOriginalValue.id = existingItem.updatedItem.id
                            findOptionGroupByNameAndUpdateValue(optionGroupToUse, editedOptionGroupName, propertyToUpdate, originalOptionName, valueToUpdate, prompts, itemOriginalValue, diff.key)//Find and update the existingItem
                            if (itemOriginalValue.value === valueToUpdate) {
                                break;
                            }
                            const newData = {
                                key: diff.key,
                                diff: editedOptionGroupName,
                                newValue: diff.value?.current ?? false,
                                whatsChanged,
                                originalValue: itemOriginalValue.value ?? false,
                            }

                            //Make sure it doesn't exist
                            if (!existingItem.diffs.some(obj => deepEqual(obj, newData))) {
                                existingItem.diffs.push(newData)
                            }
                        }
                    } else {
                        let foundItemsInMenu = entireMenu.filter(menuI => itemIndexes.includes(menuI.id))
                        for (const foundItemInMenu of foundItemsInMenu) {
                            let whatsChanged = buildWhatsChanged(sizeGroupIdInKey, sizeName, editedOptionGroupName, originalOptionName);
                            let optionGroupToUse = getOptionGroupToUse(foundItemInMenu, sizeGroupIdInKey, sizeName)
                            if (optionGroupToUse === null) {
                                continue
                            }
                            itemOriginalValue.id = foundItemInMenu.id
                            findOptionGroupByNameAndUpdateValue(optionGroupToUse, editedOptionGroupName, propertyToUpdate, originalOptionName, valueToUpdate, prompts, itemOriginalValue, diff.key)

                            if (itemOriginalValue.value === valueToUpdate) {
                                break;
                            }

                            const data = {
                                updatedItem: foundItemInMenu,
                                diffs: [{
                                    key: diff.key,
                                    diff: editedOptionGroupName,
                                    whatsChanged,
                                    newValue: diff.value?.current ?? false,
                                    originalValue: itemOriginalValue.value ?? false,
                                }]
                            }
                            foundMatchedItems.push(data)
                        }
                    }

                }
            }
            const itemIndex = foundMatchedItems.findIndex(item => item.updatedItem.originalName.toLowerCase() === menuItem.originalName.toLowerCase());
            if (itemIndex !== -1) {
                const [item] = foundMatchedItems.splice(itemIndex, 1);
                foundMatchedItems.unshift(item);
            }

        }
        setmatchingItemsWithSameOptionGroupOrSizeGroup(foundMatchedItems)
        if (foundMatchedItems.length > 1) {
            setShowAskToUpdateOtherItemsModal(true)
        }
        return foundMatchedItems;
    }

    const getOptionGroupToUse = (item: MenuItemModel, sizeGroupIdInKey: string, sizeName: string) => {
        let optionGroupToUse = item.optionGroups;

        if (sizeGroupIdInKey !== "") {
            const foundSize = item.sizeGroups[0].sizes.find(size => normalizeName(size.name) === normalizeName(sizeName));
            if (!foundSize) {
                return null; // or return whatever makes sense here
            }
            optionGroupToUse = foundSize.optionGroups;
        }

        return optionGroupToUse;
    }
    const buildWhatsChanged = (sizeGroupIdInKey: string, sizeName: string, editedOptionGroupName: string, originalOptionName: string) => {
        if (sizeGroupIdInKey !== "") {
            return `Sizes - ${sizeName} - ${editedOptionGroupName} - ${originalOptionName}`;
        }
        return `${editedOptionGroupName} - ${originalOptionName}`;
    }

    async function saveAndAugmentAll(applicationId: string) {
        const menuItemsToAugment = []
        let entireMenu = menuItems
        if (entireMenu.length === 0) {
            const result = await retrieveEntireMenu(applicationId)
            if (result.resultType === "Ok") {
                entireMenu = result.data
            }
        }

        const organizedOptionGroupToAugment: { menuItemId: string, optionGroupRefIds: string[] }[] = Object.values(
            optionGroupsToAugment.reduce((acc, { optionGroupRefId, menuItemId }) => {
                // Initialize the object for this menuItemId if it doesn't exist
                if (!acc[menuItemId]) {
                    acc[menuItemId] = { menuItemId, optionGroupRefIds: [] };
                }
                // Add the optionGroupRefId to the array for this menuItemId
                acc[menuItemId].optionGroupRefIds.push(optionGroupRefId);
                return acc;
            }, {})
        );

        for (const matchedItem of matchingItemsWithSameOptionGroupOrSizeGroup) {
            //If item id is in the itemsToNotAugment, do not save or augment it
            if (itemsToNotAugment.includes(matchedItem.updatedItem.id)) {
                continue
            }
            const { optionGroupsThatNeedReAugment, sizeGroupsThatNeedReAugment } = findOptionGroupAndSizeGroupsNeedAugment(matchedItem.updatedItem, matchedItem.diffs)

            let optionGroupReferenceIds = optionGroupsThatNeedReAugment.map(obj => obj.changedGroup.referenceId).filter(item => item);

            const matchedItemOptionGroups = organizedOptionGroupToAugment.find(it => it.menuItemId === matchedItem.updatedItem.id)
            if (matchedItemOptionGroups) {
                optionGroupReferenceIds = matchedItemOptionGroups.optionGroupRefIds
            }

            const sizeGroupIndecies = sizeGroupsThatNeedReAugment.map(obj => obj.index);
            //Augment needed
            if (optionGroupReferenceIds.length > 0 || sizeGroupIndecies.length > 0) {
                menuItemsToAugment.push({
                    itemId: matchedItem.updatedItem.id,
                    optionGroupReferenceIdsToAugment: [...new Set(optionGroupReferenceIds)],
                    sizeGroupIndeciesToAugment: sizeGroupIndecies?.length ? [0] : []
                });
                matchedItem.updatedItem.lastAugmentStartTime = new Date().getTime().toString();//Set the start time
            }
            // Save the updated menu items
            const menuUpdateResponse = await applicationNlpMenuContainer.updateVoicifyMenuItem(applicationId, matchedItem.updatedItem);
            if (menuUpdateResponse.resultType !== "Ok") {
                setErrors(menuUpdateResponse.errors)
            }

        }
        const augmentRequest = {
            applicationId,
            forceAugment: true,
            generateMenuItemColloquialNames: false,//no need to regenerate colloquial name for the menu item
            generateForOptionGroups: true,
            generateForSizeGroups: true,
            propertiesToAugment: [],
            menuItemsToAugment: menuItemsToAugment,
        }
        if (menuItemsToAugment.length > 0) {
            applicationNlpMenuContainer.augmentMenu(augmentRequest);
            setMenuItemAugmentStatus("loading");
        }

        setShowAskToUpdateOtherItemsModal(false)
    }


    /**
     * Finds the matching option in a menu item and updates the value
     * @param optionGroups 
     * @param groupName option group name
     * @param propertyToChange 
     * @param optionOriginalName the unchanged name of the option
     * @param newValue value to be set
     * @returns null
     */
    const findOptionGroupByNameAndUpdateValue = (optionGroups: MenuItemOptionGroup[], groupName: string, propertyToChange: string, optionOriginalName: string, newValue: any, prompts: string[], itemOriginalValue: { value: string | boolean, id: string, persistentProperties?: string[] }, key: string) => {
        // Base case: Iterate over all option groups at the current level
        for (let group of optionGroups) {
            // Check if the current optionGroup name matches the groupName
            if (group.name.toLowerCase() === groupName.toLowerCase()) {
                if (!key.includes("options") && prompts.includes(propertyToChange)) {//If in the advanced settings, a prompt is changed at the optionGroup level
                    itemOriginalValue.value = group[propertyToChange]
                    group[propertyToChange] = newValue
                    group.persistentProperties = itemOriginalValue.persistentProperties
                    if (propertyToChange === "colloquialName") {
                        setOptionGroupsToAugment((prev) => [...prev, { optionGroupRefId: group.referenceId, menuItemId: itemOriginalValue.id }])
                    }

                    return
                }
                const countOptionGroups = (key.match(/optionGroups/g) || []).length;
                const countOptions = (key.match(/options/g) || []).length;

                if ((countOptionGroups - countOptions === 1) && (propertyToChange === "isDisabled" || propertyToChange === "numberOfRequiredOptions" || propertyToChange === "promptIfNoSelections" && propertyToChange in group)) {
                    itemOriginalValue.value = group[propertyToChange as string]
                    group[propertyToChange as string] = newValue
                    group.persistentProperties = itemOriginalValue.persistentProperties
                    if (propertyToChange === "isDisabled" || propertyToChange === "numberOfRequiredOptions") {
                        setOptionGroupsToAugment((prev) => [...prev, { optionGroupRefId: group.referenceId, menuItemId: itemOriginalValue.id }])
                    }

                    return
                }
                const foundOption = group.options.find(option => option.name.toLowerCase() === optionOriginalName.toLowerCase())
                if (foundOption) {
                    itemOriginalValue.value = foundOption[propertyToChange]
                    foundOption[propertyToChange] = newValue
                    foundOption.persistentProperties = itemOriginalValue.persistentProperties
                    if (propertyToChange === "colloquialName") {
                        setOptionGroupsToAugment((prev) => [...prev, { optionGroupRefId: group.referenceId, menuItemId: itemOriginalValue.id }])
                    }
                    return
                }
            }

            // If the group has options, check recursively for nested optionGroups and update the value
            if (group.options && group.options.length > 0) {
                for (let option of group.options) {
                    // Some options might have optionGroups, recurse into those
                    if (option.optionGroups && option.optionGroups.length > 0) {
                        const foundGroup = findOptionGroupByNameAndUpdateValue(option.optionGroups, groupName, propertyToChange, optionOriginalName, newValue, prompts, itemOriginalValue, key);
                        if (foundGroup) {
                            const foundOption = foundGroup.options.find(option => option.name.toLowerCase() === optionOriginalName.toLowerCase())
                            if (foundOption) {
                                itemOriginalValue.value = foundOption[propertyToChange]
                                foundOption[propertyToChange] = newValue
                                return
                            }
                        }
                    }
                }
            }
        }

        // If no matching group is found, return null or undefined
        return null;
    }


    return {
        menuItem,
        activeEditId,
        path,
        isLoadingMenu,
        menuItems,
        categories,
        isMenuItemEdited,
        menuItemAugmentStatus,
        setActiveEditId,
        setMenuItem,
        setPath,
        getMenu,
        getLiveMenu,
        setCategories,
        setMenuItems,
        resetMenuItemSelections,
        updateMenuItem,
        updateMenu,
        handleDisableEnableCategory,
        syncMenuWithPOS,
        showConfirmationModal,
        setShowConfirmationModal,
        errors,
        loadingPOSMenuSync,
        publishAllChanges,
        ladingPublishAllChanges,
        setOriginalMenuItem,
        getMenuItem,
        setMenuItemAugmentStatus,
        getCleanedMenuItemForAugment,
        showAskToUpdateOtherItemsModal,
        setShowAskToUpdateOtherItemsModal,
        askToUpdateOtherItems,
        setmatchingItemsWithSameOptionGroupOrSizeGroup,
        matchingItemsWithSameOptionGroupOrSizeGroup,
        findChangesInMenuItems,
        itemsToNotAugment,
        setItemsToNotAugment,
        saveAndAugmentAll,
        pendingMenuItemUpdates,
        fetchingMenu
    };
}

const MenuContainer = createContainer(useMenuContainer);
export default MenuContainer;
