import ContentUsageModel from "../../models/analytics/api/ContentUsageModel";
import IResult from "../../models/result/IResult";
import { sendGet } from "./generic";
import { getAnalyticsApiUrl } from "../../constants/Urls";
import DatedUsageModel from "../../models/analytics/api/DatedUsageModel";
import AnalyticsInterval from "../../models/analytics/AnalyticsInterval";
import StatisticsModel from "../../models/analytics/api/StatisticsModel";
import AssistantUsageModel from "../../models/analytics/api/AssistantUsageModel";
import DeviceTargetUsageModel from "../../models/analytics/api/DeviceTargetUsageModel";
import FeatureUsageModel from "../../models/analytics/api/FeatureUsageModel";
import InterfaceUsageModel from "../../models/analytics/api/InterfaceUsageModel";
import LanguageUsageModel from "../../models/analytics/api/LanguageUsageModel";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";

// aggregations

// users
export const getContentUserAggregateByAssistant = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, contentId, 'users', 'assistant', filter);
export const getContentUserAggregateByDeviceTarget = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, contentId, 'users', 'deviceTarget', filter);
export const getContentUserAggregateByInterfaceType = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, contentId, 'users', 'interfaceType', filter);
export const getContentUserAggregateByLanguage = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, contentId, 'users', 'locale', filter);

// new users
export const getContentNewUserAggregateByAssistant = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, contentId, 'newUsers', 'assistant', filter);
export const getContentNewUserAggregateByDeviceTarget = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, contentId, 'newUsers', 'deviceTarget', filter);
export const getContentNewUserAggregateByInterfaceType = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, contentId, 'newUsers', 'interfaceType', filter);
export const getContentNewUserAggregateByLanguage = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, contentId, 'newUsers', 'locale', filter);


// interactions
export const getContentInteractionAggregateByAssistant = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, contentId, 'interactions', 'assistant', filter);
export const getContentInteractionAggregateByDeviceTarget = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, contentId, 'interactions', 'deviceTarget', filter);
export const getContentInteractionAggregateByInterfaceType = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, contentId, 'interactions', 'interfaceType', filter);
export const getContentInteractionAggregateByLanguage = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, contentId, 'interactions', 'locale', filter);

// sessions
export const getContentSessionAggregateByAssistant = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, contentId, 'sessions', 'assistant', filter);
export const getContentSessionAggregateByDeviceTarget = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, contentId, 'sessions', 'deviceTarget', filter);
export const getContentSessionAggregateByInterfaceType = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, contentId, 'sessions', 'interfaceType', filter);
export const getContentSessionAggregateByLanguage = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, contentId, 'sessions', 'locale', filter);


// abstract for halding api reqs for aggregated data
const aggregateRequest = <T = any>(applicationId: string, contentId: string, type: string, aggkey: string, filter: AnalyticsRequestFilter): Promise<IResult<T>> =>
    sendGet<T>(`/v2/content/${applicationId}/${contentId}/${type}/aggregation/${aggkey}?${buildQuery(filter)}`, getAnalyticsApiUrl());

// histograms
export const getUserHistogramForContent = (applicationId: string, contentId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, contentId, 'users', interval, filter);
export const getNewUserHistogramForContent = (applicationId: string, contentId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, contentId, 'newusers', interval, filter);
export const getUsageHistogramForContent = (applicationId: string, contentId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, contentId, 'interactions', interval, filter);
export const getSessionHistogramForContent = (applicationId: string, contentId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, contentId, 'sessions', interval, filter);


// abstract for handling api reqs for histogram data
const histogramRequest = (applicationId: string, contentId: string, type: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) => {
    const intervalString = interval.charAt(0).toUpperCase() + interval.substr(1);
    return sendGet<DatedUsageModel[]>(`/v2/content/${applicationId}/${contentId}/${type}/histogram?${buildQuery(filter)}&interval=${intervalString}`, getAnalyticsApiUrl());
}
export const getContentItemStats = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<StatisticsModel>(`/v2/content/${applicationId}/${contentId}/totals/usage?${buildQuery(filter)}`, getAnalyticsApiUrl());
}

const buildQuery = (filter: AnalyticsRequestFilter) => {
    let query = `startDate=${filter.startDate}&endDate=${filter.endDate}`;
    if (filter.assistants?.length > 0) {
        query += ("&assistants=" + filter.assistants.join("&assistants="))
    }
    if (filter.userIds?.length > 0) {
        query += ("&userIds=" + filter.userIds.join("&userIds="))
    }
    if (filter.sessionIds?.length > 0) {
        query += ("&sessionIds=" + filter.sessionIds.join("&sessionIds="))
    }
    if (filter.deviceTargetIds?.length > 0) {
        query += ("&deviceTargetIds=" + filter.deviceTargetIds.join("&deviceTargetIds="))
    }
    if (filter.locales?.length > 0) {
        query += ("&locales=" + filter.locales.join("&locales="))
    }
    return query;
}