import axios from 'axios';
import { GET_ORGANIZATIONS } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { queryStringSerialize, sendPost, sendGet, sendPut, sendDelete } from './generic';
import SignInRequestModel from '../../models/auth/SignInRequestModel';
import { VOICIFY_CLIENT_ID, getVoicifyClientSecret } from "../../constants/Keys";
import RefreshRequest from '../../models/auth/RefreshRequest';
import OrganizationModel from '../../models/organizations/api/OrganizationModel';
import MemberModel from "../../models/organizations/api/OrganizationMemberModel";
import MembershipModel from '../../models/organizations/api/MembershipModel';
import UpdateOrganizationRequest from '../../models/organizations/api/UpdateOrganizationRequest';
import MembersSearchResult from '../../models/organizations/api/MembersSearchResult';

export const getOrganizations = (): Promise<IResult<OrganizationModel[]>> => {
    return sendGet<OrganizationModel[]>(GET_ORGANIZATIONS);
}

export const getMembers = (organizationId: string): Promise<IResult<MemberModel[]>> => {
    return sendGet<MemberModel[]>((`${GET_ORGANIZATIONS}/${organizationId}/members`));   
}

export const getDetailedMemberships = (organizationId: string): Promise<IResult<MembershipModel[]>> => {
    return sendGet<MembershipModel[]>((`${GET_ORGANIZATIONS}/${organizationId}/members/detailed`));   
}

export const updateMemberRole = (organizationMemberId: string, userRoleId: string): Promise<IResult<MemberModel>> => {
    return sendPut<MemberModel>(`/organization/members/${organizationMemberId}/role`, {
        roleId: userRoleId
    });
}

export const removeMember = (organizationMemberId: string) : Promise<IResult<MemberModel>> => {
    return sendDelete<MemberModel>(`/organization/members/${organizationMemberId}/remove`);
}

export const updateOrganizationInfo = (organizationId: string, model: UpdateOrganizationRequest) => {
    return sendPut<OrganizationModel>(`/organization/${organizationId}`, model);
}

export const leaveOrganization = (organizationId: string): Promise<IResult<MemberModel>> => {
    return sendDelete<MemberModel>(`/organization/${organizationId}/leave`)
}