import { APPLICATION_TELEPHONY_DEPLOYMENT } from "../../constants/Urls";
import IResult from "../../models/result/IResult";
import TelephonyDeploymentRequest from "../../models/telephonyDeployment/TelephonyDeploymentRequest";
import { TelephonyDeploymentResponse } from "../../models/telephonyDeployment/TelephonyDeploymentResponse";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";

export const addTelephonyDeployment = (applicationId: string, request: TelephonyDeploymentRequest): Promise<IResult<TelephonyDeploymentResponse>> => {
    return sendPost(`${APPLICATION_TELEPHONY_DEPLOYMENT}/${applicationId}/provision/vonage`, request);
};

export const getTelephonyDeployments = (applicationId: string): Promise<IResult<TelephonyDeploymentResponse[]>> => {
    return sendGet(`${APPLICATION_TELEPHONY_DEPLOYMENT}/${applicationId}`);
};

export const deleteTelephonyDeployment = (applicationId: string, phoneNumber: string): Promise<IResult<TelephonyDeploymentResponse[]>> => {
    return sendDelete(`${APPLICATION_TELEPHONY_DEPLOYMENT}/${applicationId}/phoneNumber/${phoneNumber}`);
};

export const updateTelephonyDeployment = (applicationId: string, phoneNumber: string, request: TelephonyDeploymentRequest): Promise<IResult<TelephonyDeploymentResponse>> => {
    return sendPut(`${APPLICATION_TELEPHONY_DEPLOYMENT}/${applicationId}/phoneNumber/${phoneNumber}`, request);
};