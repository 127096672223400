export const BytesToMegabytesMultiplier: number = 9.537e-7;
export const BytesToGigabytesMultiplier: number = 9.313225746154785e-10;
export const MegabytesSize: number = 1024 * 1024;
export const GigabytesSize: number = 1024 * 1024 * 1024;

export const MaxAudioBytesSize: number = 2 * GigabytesSize; // 2GB
export const MaxBackupBytesSize: number = 100 * MegabytesSize; // 100MB
export const MaxImageBytesSize: number = 5 * MegabytesSize; // 5MB
export const MaxVideoBytesSize: number = 2 * GigabytesSize; // 2GB

type UploadFileType = "AudioFile" | "VideoFile" | "ImageFile" | "TextFile" | "BackupFile";

export const getFileTypeFromFileName = (fileName: string): UploadFileType => {
    if(!fileName) return null;
    
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1].toLowerCase();
    switch (extension) {
        case "mp3": 
        case "mp4": return "VideoFile";

        case "vfy":
        case "zip": return "BackupFile";

        case "txt": 
        case "csv": return "TextFile";
        
        case "png":
        case "jpg":
        case "jpeg":
        default: return "ImageFile";
    }
};

