import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../../shared/state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import OrganizationContainer from '../../../../shared/state/containers/OrganizationContainer';
import TemplateFormSectionModel from '../../../../shared/models/templating/api/TemplateFormSectionModel';
import TemplateFormFieldValueModel from '../../../../shared/models/templating/api/TemplateFormFieldValueModel';
import Loader from '../../../../shared/components/general/Loader';
import FullPagePanel from '../../../../shared/components/structure/Panels/FullPagePanel';
import BackButton from '../../../../shared/components/general/BackButton';
import HeaderTitleView from '../../../../shared/components/structure/HeaderTitleView';
import PanelContainer from '../../../../shared/components/structure/Panels/PanelContainer';
import PageError from '../../../../shared/components/general/PageError';
import { color_shades_dark, color_shades_lighter, color_variants_main_bg } from '../../../../shared/constants/colors';
import PanelHeaderContainer from '../../../../shared/components/structure/Panels/PanelHeaderContainer';
import ScrollablePanelBody from '../../../../shared/components/structure/ScrollablePanelBody';
import AppliedAppTemplateSectionMenu from '../../../../shared/scenes/applicationAppliedTemplateManager/components/AppliedAppTemplateSectionMenu';
import CollapsedMenuPanel from '../../../../shared/components/structure/CollapsedMenuPanel';
import CollapsingPanel from '../../../../shared/components/structure/Panels/CollapsingPanel';
import ExpandedMenuPanel from '../../../../shared/components/structure/ExpandedMenuPanel';
import UpdateAppliedAppTemplateForm from './UpdateAppliedAppTemplateForm';
import SparkTemplateFormSectionModel from '../../../../shared/models/templating/api/SparkTemplateFormSectionModel';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';
import ApplicationEnvironmentsContainer from '../../../../shared/state/containers/ApplicationEnvironmentsContainer';
import ConfirmationDialog from '../../../../shared/components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../../shared/components/general/WarningConfirmation';
import _, { set } from 'lodash';
import ApplicationAnalyticsFacetContainer from '../../../../shared/state/containers/ApplicationAnalyticsFacetContainer';
import ApplicationAnalytics from '../../../../shared/scenes/applicationAnalytics';
import TelephonyDeploymentContainer from '../../../../shared/hooks/TelephonyDeploymentContainer';
import TelephonyDeploymentRequest from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentRequest';
import SparkTelephonyDetailsForm from './SparkTelephonyDetailsForm';
import { female1Voice, female2Voice, female3Voice, female4Voice, male1Voice, male2Voice, male3Voice, male4Voice } from '../../../../shared/constants/sparkConstants/voices';
import HeaderSeparator from '../../../../shared/components/structure/Panels/HeaderSeparator';
import ProfileMenu from '../../../../shared/scenes/profileMenu';
import { TabPanel } from 'react-tabs';
import FullPageTabbedPanel from '../../../../shared/components/structure/Panels/FullPageTabbedPanel';
import ContentCollapsingSection from '../../../../shared/components/structure/ContentCollapsingSection';
import SettingsCard from '../../../../shared/components/structure/SettingsCard';
import EmptyView from '../../../../shared/scenes/applicationModules/components/EmptyView';
import { TelephonyDeploymentResponse } from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentResponse';
import SparkTelephonyDeploymentForm from './SparkTelephonyDeploymentForm';
import { voiceOptions } from '../../../../shared/constants/sparkConstants/voiceOptions';
import { v4 as uuidv4 } from 'uuid';
import AppliedAppTemplateReviewForm from './AppliedAppTemplateReviewForm';
import TemplateConfigurationContainer from '../../../../shared/state/containers/TemplateConfigurationContainer';
import { getOfferTextTemplateConfigId, getRespondTemplateConfigId, getTransferTemplateConfigId } from '../../../../shared/constants/sparkConstants/customRequestModuleIds';
import SparkDeploymentContainer from '../../../../shared/hooks/SparkDeploymentContainer';
import { formatPhoneNumber } from '../basicOrganizationApplications/components/ApplicationItem';
import MenuManagement from './MenuManagement';
import { Route } from 'react-router';
import ApplicationMenuItemManager from '../../../../shared/scenes/applicationMenuItem';
import useIsMobile from '../../../../shared/hooks/UseIsMobile';
import MenuSyncContainer from '../../../../shared/hooks/MenuSyncContainer';
import StatusIndicator from '../../../../shared/components/general/StatusIndicator';
import MenuContainer from '../../../../shared/hooks/MenuContainer';
import MediaContainer from '../../../../shared/state/containers/MediaContainer';
import { AutoGenerateFormFieldResponsesForm } from './autoGenerateFormField/AutoGenerateFormFieldResponsesForm';
import SearchField from '../../../../shared/components/forms/SearchField';
import Button from '../../../../shared/components/general/Button';

const analyticsIcon = require('../../../../shared/content/images/tab-icons/analytics.svg');
const formIcon = require('../../../../shared/content/images/task-checklist-check-white.svg');
const voiceIcon = require('../../../../shared/content/images/tab-icons/voice.svg');
const voiceIconBlue = require('../../../../shared/content/images/tab-icons/voice-blue.svg');
const menuIcone = require('../../../../shared/content/images/tab-icons/menu.svg');
const downArrowIcon = require('../../../../shared/content/images/down_arrow.svg');

interface AppliedTemplateManagerContentProps {
    applicationId: string
    templateConfigurationId?: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    orgContainer: OrganizationContainer
    history: any
    applicationEnvironmentsContainer: ApplicationEnvironmentsContainer
    facetContainer: ApplicationAnalyticsFacetContainer
    templateConfigContainer: TemplateConfigurationContainer
    appliedAppTemplateId: string
    mediaContainer: MediaContainer
};

export interface AppendedSection {
    id: string
    name: string
    isComplete: boolean
    isSelected: boolean
}

export type ApplicationType = "common" | "location" | null;

const ApplicationManagerContent: React.FC<AppliedTemplateManagerContentProps> = (props) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isLeftExpanded, setIsLeftExpanded] = useState<boolean>(true);
    const [isFinishSelected, setIsFinishSelected] = useState<boolean>(false);
    const [currentSection, setCurrentSection] = useState<SparkTemplateFormSectionModel>(null);
    const [areNoTemplateFormSections, setAreNoTemplateFormSections] = useState<boolean>(false);
    const [combinedFormSections, setCombinedFormSections] = useState<SparkTemplateFormSectionModel[]>([]);
    const [incompleteSections, setIncompleteSections] = useState<TemplateFormSectionModel[]>([]);
    const [closeModalIsOpen, setCloseModalIsOpen] = useState<boolean>(false);
    const [applicationType, setApplicationType] = useState<ApplicationType>();
    const [isLoadingTelephonyDeployments, setIsLoadingTelephonyDeployments] = useState<boolean>(false);
    const [telephonyDeployments, setTelephonyDeployments] = useState<TelephonyDeploymentResponse[]>([]);
    const [isLoadingApplicationType, setIsLoadingApplicationType] = useState<boolean>(true);
    const [telephonyDeploymentSection, setTelephonyDeploymentSection] = useState<AppendedSection>(null);
    const [autoGenerateFormResponsesSection, setAutoGenerateFormResponsesSection] = useState<AppendedSection>(null);
    const [appendedSections, setAppendedSections] = useState<AppendedSection[]>([]);
    const [phoneNumberDeploymentErrorText, setPhoneNumberDeploymentErrorText] = useState<string>("");
    const [tabs, setTabs] = useState<any[]>([]);
    const [importedFormFields, setImportedFormFields] = useState<TemplateFormFieldValueModel[]>([]);
    const [fileImportErrors, setFileImportErrors] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [menuSyncing, setMenuSyncing] = useState<boolean>(false);
    const [selectedMenuCategoryIndex, setSelectedMenuCategoryIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [nextSearchOccurance, setNextSearchOccurance] = useState<number>(0)
    const [isLoadingAutoGenerateAnswer, setIsLoadingAutoGenerateAnswer] = useState(false);

    const telephonyDeploymentContainer = TelephonyDeploymentContainer.useContainer();
    const trainingContainer = SparkDeploymentContainer.useContainer();
    const menuSyncContainer = MenuSyncContainer.useContainer();
    const menuContainer = MenuContainer.useContainer();
    const isMobile = useIsMobile();

    useEffect(() => {
        initialLoad();
    }, []);

    useEffect(() => {
        if (!loaded) {
            return;
        }
        loadApplicationType();
    }, [loaded, props.appContainer.state.currentApp]);

    useEffect(() => {
        const appliedTemplate = (section: SparkTemplateFormSectionModel) => {
            return props.appliedAppTemplateContainer.state.appliedTemplates?.find(at => at.id == section.appliedTemplateId);
        };
        const tempSections = getSections(importedFormFields)
        let newCombinedFormSections = JSON.parse(JSON.stringify(tempSections))
        if (searchTerm !== "") {
            newCombinedFormSections = newCombinedFormSections.filter(newCombinedFormSection => {
                //If the saved value includes the search term
                const matchedAppliedTemplateId = appliedTemplate(newCombinedFormSection).templateFormFieldValues.find(fv => fv.value?.toLowerCase().includes(searchTerm))?.templateFormFieldId
                if (newCombinedFormSection.section.templateFormFields.some(tf => tf.id === matchedAppliedTemplateId)) {
                    return true
                }

                // If the title of the section includes the search term
                if (newCombinedFormSection.section.title.toLowerCase().includes(searchTerm)) {
                    return true;
                }

                //If title or label of the field includes the search term
                for (const templateFormField of newCombinedFormSection.section.templateFormFields) {
                    if (templateFormField.title?.toLowerCase().includes(searchTerm) || templateFormField.label?.toLowerCase().includes(searchTerm)) {
                        return true;
                    }
                }
                return false

            })

        }
        if (nextSearchOccurance !== 0 && newCombinedFormSections[nextSearchOccurance] !== undefined) {
            setCurrentSection(newCombinedFormSections[nextSearchOccurance]);
        } else {
            if (newCombinedFormSections.length === 0) {
                setCurrentSection(tempSections[0]);
            } else {
                setCurrentSection(newCombinedFormSections[0]);
            }
            setNextSearchOccurance(0)
        }
    }, [searchTerm, nextSearchOccurance])

    useEffect(() => {
        const newMenuSyncing = menuSyncContainer.isApplicationSyncing(props.appContainer?.state?.currentApp?.id);
        if (menuSyncing) {
            if (!newMenuSyncing) {
                menuContainer.getLiveMenu(props.appContainer?.state?.currentApp?.id);
            }
        }
        setMenuSyncing(newMenuSyncing);
    }, [menuSyncContainer.syncingMenus]);

    useEffect(() => {
        trainingContainer.setCurrentOrgId(props.orgContainer.state.currentOrganization.id);
    }, [props?.orgContainer?.state?.currentOrganization?.id]);

    useEffect(() => {
        if (loaded) {
            setSelectedTab(getSelectedIndex())
        }
    }, [props.history.location.pathname, tabs, loaded]);

    useEffect(() => {
        if (!loaded) return;
        setTabs(getTabs());
    }, [telephonyDeploymentContainer.telephonyDeployments, loaded]);

    useEffect(() => {
        // this is where we combine all applied template form sections into one form and sort based on priority
        if (!loaded) return;
        const formSections: SparkTemplateFormSectionModel[] = [];
        const orgAppliedTemplates = props.appliedAppTemplateContainer.state.perApplicationAppliedTemplates ?? [];
        if (orgAppliedTemplates.length) {
            const appAppliedTemplates = orgAppliedTemplates.find((template) => template.applicationId === props.applicationId);
            const sortedAppAppliedTemplates = _.sortBy(appAppliedTemplates?.appliedTemplates ?? [], a => a?.createdDate);
            let priority = 0;
            sortedAppAppliedTemplates?.forEach((appliedTemplate) => {
                if (appliedTemplate.templateForm?.templateConfigurationId === getRespondTemplateConfigId()
                    || appliedTemplate.templateForm?.templateConfigurationId === getTransferTemplateConfigId()
                    || appliedTemplate.templateForm?.templateConfigurationId === getOfferTextTemplateConfigId()
                ) {
                    appliedTemplate.templateForm?.templateFormSections?.forEach((section) => {
                        priority += 1;
                        formSections.push(
                            {
                                appliedTemplateId: appliedTemplate.id,
                                section: { ...section, priority: priority, title: appliedTemplate.name, isCustom: true }
                            }
                        );
                    });
                } else {
                    var showTitle = false;
                    if (appliedTemplate.templateForm?.templateFormSections.length > 1) {
                        showTitle = true;
                    }
                    appliedTemplate.templateForm?.templateFormSections?.forEach((section) => {
                        priority += 1;
                        var name = appliedTemplate.name;
                        if (showTitle || !name) {
                            name = section.title;
                        }

                        //we found a duplicate title
                        var otherSectionIndex = formSections.findIndex(form => form.section.title === name);
                        if (otherSectionIndex >= 0) {
                            //set the new title for both the current and the prviouse duplicate
                            var otherSection = formSections[otherSectionIndex];
                            var otherOgSection = appliedTemplate.templateForm?.templateFormSections.find(sec => sec.id === otherSection.section.id);
                            var otherOgAppliedTemplate = sortedAppAppliedTemplates.find(temp => temp.id == otherSection.appliedTemplateId);

                            formSections[otherSectionIndex].section.name = otherOgSection.title + "-" + otherOgAppliedTemplate.name;
                            name = section.title + "-" + appliedTemplate.name;
                        }


                        formSections.push(
                            {
                                appliedTemplateId: appliedTemplate.id,
                                section: { ...section, priority: priority, title: name }
                            }
                        );
                    });
                }
            })
        }
        setCombinedFormSections(formSections);
        if (formSections.length > 0) {
            setCurrentSection(formSections[0]);
        }
    }, [props.appliedAppTemplateContainer.state.appliedTemplates, loaded])

    useEffect(() => {
        if (!loaded || isLoadingApplicationType) return;
        setTelephonyDeployments(telephonyDeploymentContainer.telephonyDeployments);
        let updatedSections = [...appendedSections];

        const updatePhoneNumberSection = () => {
            const phoneNumberSectionExists = updatedSections.some(section => section.name === "Phone Number");
            if (telephonyDeploymentContainer.telephonyDeployments.length > 0) {
                // Remove Phone Number section if telephony deployments exist
                updatedSections = updatedSections.filter(section => section.name !== "Phone Number");
            } else if (applicationType !== "common" && !phoneNumberSectionExists && !isFinishSelected) {
                // Add Phone Number section if it doesn't already exist
                updatedSections.push({
                    id: uuidv4(),
                    name: "Phone Number",
                    isComplete: false,
                    isSelected: false,
                });
            }
        };
        const updateAutoGenerateResponsesSection = () => {
            if (combinedFormSections.length > 0) {
                const allTemplates = props.appliedAppTemplateContainer.state.appliedTemplates;

                // if we have a template form field value that is NOT the associated variable that we should remove it.
                let showSection = true;
                for (var appliedTemplates of allTemplates) {
                    if (appliedTemplates?.templateFormFieldValues?.some(tffv => tffv?.value && tffv?.value !== tffv?.associatedVariable && tffv?.value !== tffv?.defaultValue)) {
                        showSection = false;
                        break;
                    }

                }
                if (!showSection) {
                    updatedSections = updatedSections.filter(section => section.name !== "Auto-Generate Responses");
                } else {
                    const autoGenerateResponsesSectionExists = updatedSections.some(section => section.name === "Auto-Generate Responses");
                    // Add Auto-Generate Responses section if it doesn't already exist
                    if (!autoGenerateResponsesSectionExists) {
                        updatedSections.push({
                            id: uuidv4(),
                            name: "Auto-Generate Responses",
                            isComplete: false,
                            isSelected: false,
                        });
                    }
                }

            }
        };

        updatePhoneNumberSection();
        updateAutoGenerateResponsesSection();

        setAppendedSections(updatedSections);
    }, [
        applicationType,
        props.applicationId,
        telephonyDeploymentContainer.telephonyDeployments,
        loaded,
        isLoadingApplicationType,
    ]);

    const initialLoad = async () => {
        const tasks = [];
        tasks.push(props.appContainer.loadCurrentAppById(props.applicationId));
        tasks.push(props.appliedAppTemplateContainer.loadAppliedTemplates(props.applicationId));
        tasks.push(telephonyDeploymentContainer.getTelephonyDeployments(props.applicationId));
        tasks.push(props.appliedAppTemplateContainer?.getLoadedTemplatesForApp(props.applicationId));
        await Promise.all(tasks);
        setLoaded(true);
    };

    const getTabs = (existingTelephonyDeployment?: boolean) => {
        if ((telephonyDeploymentContainer.telephonyDeployments.length > 0) || existingTelephonyDeployment) {
            const returnArray =
                [{
                    title: "Form",
                    icon: formIcon,
                    selectedIcon: formIcon
                }, {
                    title: "Phone",
                    icon: voiceIcon,
                    selectedIcon: voiceIcon
                }, {
                    title: "Analytics",
                    icon: analyticsIcon,
                    selectedIcon: analyticsIcon
                },
                ];
            if (props.appContainer.state.currentApp.manageMenuApplicationId?.length) {
                returnArray.push(
                    {
                        title: "Menu",
                        icon: menuIcone,
                        selectedIcon: menuIcone
                    }
                );
            }
            return returnArray;
        } else {
            return (
                [{
                    title: "Form",
                    icon: formIcon,
                    selectedIcon: formIcon
                }]
            )
        }
    };

    const loadTelephonyDeployments = async () => {
        setIsLoadingTelephonyDeployments(true);
        const telephonyDeploymentsResponse = await telephonyDeploymentContainer.getTelephonyDeployments(props.applicationId);
        setTelephonyDeployments(telephonyDeploymentsResponse.data);
        setIsLoadingTelephonyDeployments(false);
    };

    const loadApplicationType = async () => {
        if (!props.appContainer.state.currentApp) return;
        const currentApp = props.appContainer.state.currentApp;
        setApplicationType(currentApp?.allowsTemplating ? "common" : "location");
        setIsLoadingApplicationType(false);
    };

    const startListeningForTraining = (appliedTemplateSyncId?: string) => {
        if (appliedTemplateSyncId) {
            trainingContainer.pollForApplication(
                props.applicationId,
                props?.orgContainer?.state?.currentOrganization?.id,
                appliedTemplateSyncId
            );
        } else {
            trainingContainer.pollForApplication(
                props.applicationId,
                props?.orgContainer?.state?.currentOrganization?.id
            );
        }
    };

    const getVoice = (voice: string) => {
        switch (voice) {
            case female1Voice:
                return "female1";
            case female2Voice:
                return "female2";
            case female3Voice:
                return "female3";
            case female4Voice:
                return "female4";
            case male1Voice:
                return "male1";
            case male2Voice:
                return "male2";
            case male3Voice:
                return "male3";
            case male4Voice:
                return "male4";
            default:
                return "custom";
        }
    };

    const getVoiceString = (voice: string) => {

        let ttsVoice = "";

        switch (voice) {
            case "female1":
                ttsVoice = female1Voice;
                break;
            case "female2":
                ttsVoice = female2Voice;
                break;
            case "female3":
                ttsVoice = female3Voice;
                break;
            case "female4":
                ttsVoice = female4Voice;
                break;
            case "male1":
                ttsVoice = male1Voice;
                break;
            case "male2":
                ttsVoice = male2Voice;
                break;
            case "male3":
                ttsVoice = male3Voice;
                break;
            case "male4":
                ttsVoice = male4Voice;
                break;
            default:
                ttsVoice = female1Voice;
                break;
        }

        return ttsVoice;

    };

    const handleSubmitTelephonyForm = async (phoneNumber: string, telephonyDeploymentRequest: TelephonyDeploymentRequest) => {
        const telephonyDeployment = telephonyDeploymentContainer.telephonyDeployments.find(td => td.phoneNumber === phoneNumber);

        setIsLoadingTelephonyDeployments(true);

        let ttsProvider = "ElevenLabs";
        let ttsVoice = female1Voice;

        switch (telephonyDeploymentRequest.ttsVoice) {
            case "female1":
                break;
            case "female2":
                ttsVoice = female2Voice;
                break;
            case "female3":
                ttsVoice = female3Voice;
                break;
            case "female4":
                ttsVoice = female4Voice;
                break;
            case "male1":
                ttsVoice = male1Voice;
                break;
            case "male2":
                ttsVoice = male2Voice;
                break;
            case "male3":
                ttsVoice = male3Voice;
                break;
            case "male4":
                ttsVoice = male4Voice;
                break;
            default:
                ttsProvider = telephonyDeployment.ttsProvider;
                ttsVoice = telephonyDeployment.ttsVoice;
                break;
        }

        telephonyDeploymentRequest.ttsProvider = ttsProvider;
        telephonyDeploymentRequest.ttsVoice = ttsVoice;

        await telephonyDeploymentContainer.updateTelephonyDeployment(props.applicationId, phoneNumber, telephonyDeploymentRequest);

        await loadTelephonyDeployments();

        setIsLoadingTelephonyDeployments(false);
    };

    const getCurrentAppliedTemplate = () => {
        if (currentSection?.appliedTemplateId?.length) {
            return props.appliedAppTemplateContainer.state.appliedTemplates?.find(at => at.id == currentSection.appliedTemplateId);
        } else {
            return null;
        }
    };

    const getCurrentSectionIndex = () => {
        const appliedTemplateSections = combinedFormSections.map((formSection) => formSection.section) ?? [];
        const section = appliedTemplateSections.find(s => (currentSection?.section?.title && s.title === currentSection?.section?.title)
            || (currentSection?.section?.dynamicFormFields?.length && s.title === currentSection?.section?.title));
        if (section) {
            return appliedTemplateSections.findIndex(s => s.title == section.title);
        } else {
            const appendedSectionIndex = appendedSections.findIndex(section => section.isSelected);
            return appliedTemplateSections.length + appendedSectionIndex;
        }
    };

    const handleToggleExpandLeft = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, expanded: boolean) => {
        setIsLeftExpanded(expanded);
        localStorage.setItem("contentMenu", expanded?.toString());
        e?.stopPropagation();
    };

    const calculateIncompleteSections = (templateFormFieldValues?: TemplateFormFieldValueModel[]) => {
        const incompleteFormSections = [];
        props.appliedAppTemplateContainer.state.appliedTemplates?.forEach((appliedTemplate) => {
            let incompleteSections: TemplateFormSectionModel[] = [];
            if (templateFormFieldValues?.length > 1) {
                for (const templateFormFieldValue of templateFormFieldValues) {
                    if (!templateFormFieldValue.value) {
                        incompleteSections.push(appliedTemplate.templateForm.templateFormSections.find(section => section.title == templateFormFieldValue.title));
                    }
                }
            } else {
                incompleteSections = props.appliedAppTemplateContainer.getIncompleteSections(appliedTemplate) ?? [];
            }
            incompleteSections.forEach(section => { incompleteFormSections.push(section) })
        });
        appendedSections.forEach(section => {
            if (!section.isComplete) {
                incompleteFormSections.push(section);
            }
        });
        setIncompleteSections(incompleteFormSections);
    };

    const handleFinishSelected = () => {
        setIsFinishSelected(true);
        calculateIncompleteSections(importedFormFields);
        setCurrentSection(null);
        setTelephonyDeploymentSection(null);
        setAutoGenerateFormResponsesSection(null);
        if (appendedSections.length > 0) {
            setAppendedSections(appendedSections.map(section => ({ ...section, isSelected: false })));
        }
        if (autoGenerateFormResponsesSection) {
            handleRemoveSection(autoGenerateFormResponsesSection);
        };
    };

    const handleAppendedSectionSelected = (section: AppendedSection) => {
        switch (section.name) {
            case "Phone Number":
                telephonyDeploymentContainer.clearErrors();
                setPhoneNumberDeploymentErrorText("");
                setAutoGenerateFormResponsesSection(null);
                setTelephonyDeploymentSection({ ...section, isSelected: true });
                break;
            case "Auto-Generate Responses":
                setTelephonyDeploymentSection(null);
                setAutoGenerateFormResponsesSection({ ...section, isSelected: true });
                break;
            default:
                break;
        };
        const matchingSectionIndex = appendedSections.findIndex(s => s.id === section.id);
        if (appendedSections.length > 0 && matchingSectionIndex !== -1) {
            const appendedSectionsCopy = [...appendedSections];
            for (const [index, appendedSection] of appendedSectionsCopy.entries()) {
                if (index === matchingSectionIndex) {
                    appendedSectionsCopy[index].isSelected = true;
                } else {
                    appendedSectionsCopy[index].isSelected = false;
                }
            };

            setAppendedSections(appendedSectionsCopy);
        };
        calculateIncompleteSections(importedFormFields);
        setCurrentSection(null);
        setIsFinishSelected(false);
    };

    const handleSectionSelected = (section: TemplateFormSectionModel, appliedTemplateId: string) => {
        setCurrentSection({
            appliedTemplateId: appliedTemplateId,
            section: section
        });
        setTelephonyDeploymentSection(null);
        setAutoGenerateFormResponsesSection(null);
        if (appendedSections.length > 0) {
            setAppendedSections(appendedSections.map(section => ({ ...section, isSelected: false })));
        }
        setIsFinishSelected(false);
        setIncompleteSections([]);
    };

    const handleNextSection = async () => {
        const currentIndex = getCurrentSectionIndex();
        const totalSectionsLength = combinedFormSections.length + appendedSections.length;
        const lastCombinedFormSectionIndex = combinedFormSections.length - 1;
        const lastSectionIndex = lastCombinedFormSectionIndex + appendedSections.length;
        if (totalSectionsLength > 0) {
            if (currentIndex === lastSectionIndex) {
                handleFinishSelected();
            } else {
                if (currentSection) {
                    const currentSectionIndex = combinedFormSections.findIndex(combinedFormSection => combinedFormSection.section.title == currentSection.section.title)
                    if (currentSectionIndex === combinedFormSections.length - 1) { // if the last combined section is selected
                        if (appendedSections.length > 0) {
                            handleAppendedSectionSelected(appendedSections[0]);
                        }
                    } else {
                        const nextFormSectionIndex = combinedFormSections.findIndex((formSection) => formSection.section.title == currentSection.section.title);
                        setCurrentSection(combinedFormSections[nextFormSectionIndex + 1]);
                    }
                } else if (telephonyDeploymentSection) {
                    if (appendedSections.indexOf(telephonyDeploymentSection) === appendedSections.length - 1) { // if the last appended section is selected
                        handleFinishSelected();
                    } else {
                        if (appendedSections.length > 0) {
                            const phoneNumberSectionIndex = appendedSections.findIndex(appendedSection => appendedSection.name === "Phone Number");
                            handleAppendedSectionSelected(appendedSections[phoneNumberSectionIndex + 1]);
                            handleRemoveSection(telephonyDeploymentSection);
                        }
                    }
                } else if (autoGenerateFormResponsesSection) {
                    if (appendedSections.indexOf(autoGenerateFormResponsesSection) === appendedSections.length - 1) { // if the last appended section is selected
                        handleFinishSelected();
                    } else {
                        if (appendedSections.length > 0) {
                            const autoGenerateFormResponsesSectionIndex = appendedSections.findIndex(appendedSection => appendedSection.name === "Auto-Generate Responses");
                            handleAppendedSectionSelected(appendedSections[autoGenerateFormResponsesSectionIndex + 1]);
                            handleRemoveSection(autoGenerateFormResponsesSection);
                        }
                    }
                } else {
                    handleFinishSelected();
                    setAreNoTemplateFormSections(true);
                }
            }
        }
    };

    const handlePreviousSection = async () => {
        let currentDynamicSectionName: string;
        if (currentSection?.section?.name) {
            currentDynamicSectionName = currentSection?.section?.name
        }
        let currentIndex = getCurrentSectionIndex();
        if (areNoTemplateFormSections) {
            currentIndex = 0;
            setIsFinishSelected(false);
            setCurrentSection(null);
            setAreNoTemplateFormSections(false);
            return;
        }
        if (currentIndex == 0) {
            setIsFinishSelected(false);
            setCurrentSection(null);
            return;
        } else if (currentIndex === -1 && isFinishSelected) {
            currentIndex = combinedFormSections.length
        }
        const prevSection = combinedFormSections[currentIndex - 1];
        if (prevSection) {
            setCurrentSection(prevSection);
            if (appendedSections.length > 0) {
                setAppendedSections(appendedSections.map(section => ({ ...section, isSelected: false })));
            }
            setTelephonyDeploymentSection(null);
            setAutoGenerateFormResponsesSection(null);
        } else {
            setCurrentSection(null);
        }
        if (currentDynamicSectionName) {
            await props.appliedAppTemplateContainer.removeDynamicFormSection(currentSection.appliedTemplateId, currentDynamicSectionName);
        }
        setAreNoTemplateFormSections(false);
    };

    const handleRemoveSection = (sectionToRemove: AppendedSection) => {
        const sectionToRemoveIndex = appendedSections.findIndex(section => section.id === sectionToRemove.id);
        const appendedSectionsWithoutRemovedSection = [
            ...appendedSections.slice(0, sectionToRemoveIndex),
            ...appendedSections.slice(sectionToRemoveIndex + 1)
        ];
        setAppendedSections(appendedSectionsWithoutRemovedSection);
    };

    const removeSectionFromIncompleteSections = (section: AppendedSection) => {
        setIncompleteSections([
            ...incompleteSections.filter(incompleteSection => incompleteSection.id !== section.id),
            section
        ]);
    };

    const toggleCloseModal = () => {
        setCloseModalIsOpen(!closeModalIsOpen);
    };

    const handleClose = () => {
        toggleCloseModal();
    };

    const handleInsertDynamicFormSection = async (dynamicSection: TemplateFormSectionModel) => {
        const newSection = await props.appliedAppTemplateContainer.insertDynamicFormSection(getCurrentAppliedTemplate().id, dynamicSection, currentSection.section)
        setIsFinishSelected(false);
        setCurrentSection({
            appliedTemplateId: getCurrentAppliedTemplate().id,
            section: newSection
        });
    };

    const getPageCount = () => {
        if (!appendedSections) {
            return (combinedFormSections?.length ?? 0) + 1;
        } else {
            return (combinedFormSections?.length ?? 0) + 1 + appendedSections.length;
        }
    };

    const getSections = (importedFormFields?: TemplateFormFieldValueModel[]) => {

        const appliedTemplate = (section: SparkTemplateFormSectionModel) => {
            return props.appliedAppTemplateContainer.state.appliedTemplates?.find(at => at.id == section.appliedTemplateId);
        };
        const newCombinedFormSections = combinedFormSections?.map(s => {

            const isComplete = () => {
                if (importedFormFields.length > 1) {
                    for (const templateFormFieldValue of importedFormFields) {
                        if (!templateFormFieldValue.value) {
                            const matchingSection = appliedTemplate(s)?.templateForm?.templateFormSections.find(section => section.title == templateFormFieldValue.title);
                            if (matchingSection) {
                                return false;
                            }
                        }
                    }
                    return true;
                } else {
                    return props.appliedAppTemplateContainer.getIsSectionOfCurrentComplete(appliedTemplate(s), s.section);
                }
            };
            return {
                appliedTemplateId: s.appliedTemplateId,
                section: s.section,
                isComplete: isComplete()
            };

        });

        return newCombinedFormSections;

    };

    const getCurrentPageNumber = () => {
        if (isFinishSelected) return getPageCount();
        return getCurrentSectionIndex() + 1;
    };

    const getDefaultTemplateValues = () => {
        const templateValues = [];
        combinedFormSections?.forEach(formSection => {
            formSection?.section?.templateFormFields?.forEach(formField => {
                if (formField?.defaultValue) {
                    const fieldValue: TemplateFormFieldValueModel = {
                        value: formField?.defaultValue,
                        fieldType: formField?.fieldType,
                        tip: formField?.tip,
                        title: formField?.title,
                        label: formField?.label,
                        defaultValue: formField?.defaultValue,
                        associatedVariable: formField?.associatedVariable,
                        placeholder: formField?.placeholder,
                        templateFormFieldId: formField?.id
                    };
                    templateValues.push(fieldValue);
                }
            })
        })
        return templateValues;
    };

    const setPageErrorText = (text: string) => {
        setPhoneNumberDeploymentErrorText(text);
    };

    const renderMenu = () => {
        return (
            <>
                <PanelHeaderContainer className={stepHeaderWrapper}>
                    <div className="page-indicator-container">
                        <p>Step {getCurrentPageNumber()} of {getPageCount()}</p>
                    </div>
                </PanelHeaderContainer>
                <SearchFieldButtonWrapper>
                    <StyledSearchField
                        id="searchField"
                        name={"searchField"}
                        placeholder={"Search..."}
                        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                        onKeyDown={(e) => e.key === "Enter" && setNextSearchOccurance(nextSearchOccurance + 1)}
                        boxType={"square"}
                    />
                    <Button themes={["flat"]}
                        onClick={() => setNextSearchOccurance(nextSearchOccurance + 1)}
                        className={nextSearchBtnStyle}
                        icon={downArrowIcon}
                        iconClassName={downArrowIconStyle}
                    />
                </SearchFieldButtonWrapper>

                <AppliedAppTemplateSectionMenu
                    handleToggleExpandLeft={handleToggleExpandLeft}
                    fileImportErrors={fileImportErrors}
                    setFileImportErrors={setFileImportErrors}
                    importedFormFields={importedFormFields}
                    setImportedFormFields={setImportedFormFields}
                    history={props.history}
                    applicationId={props.applicationId}
                    appContainer={props.appContainer}
                    appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                    activeSection={currentSection ? { section: currentSection.section, isComplete: props.appliedAppTemplateContainer.getIsSectionOfCurrentComplete(getCurrentAppliedTemplate(), currentSection.section) } : telephonyDeploymentSection && telephonyDeploymentSection}
                    sections={importedFormFields ? getSections(importedFormFields) : getSections()}
                    isFinishSelected={isFinishSelected}
                    onFinishSelect={handleFinishSelected}
                    onSectionSelect={handleSectionSelected}
                    onAppendedSectionSelect={handleAppendedSectionSelected}
                    onStartSelect={null}
                    hideStart={true}
                    appendedSections={appendedSections}
                    templateConfigContainer={props.templateConfigContainer}
                    orgContainer={props.orgContainer}
                    appliedAppTemplateId={props.appliedAppTemplateId}
                />

            </>);
    };

    const renderTelephonyDetails = () => {
        return (
            <ScrollablePanelBody className={paddedBody}>
                <SettingsCard title="Voice">
                    {isLoadingTelephonyDeployments || telephonyDeploymentContainer.isLoading ? <Loader /> :
                        telephonyDeployments.length > 0 ? (
                            telephonyDeployments.map((telephonyDeployment, telephonyDeploymentIndex) => (
                                <ContentCollapsingSection
                                    key={telephonyDeployment.phoneNumber}
                                    icon={voiceIconBlue}
                                    title={telephonyDeployment.virtualNumber ? telephonyDeployment.virtualNumber : formatPhoneNumber(telephonyDeployment.phoneNumber)}
                                    isCollapsedByDefault={telephonyDeploymentIndex === 0 ? false : true}
                                    className={collapsedSection}
                                >
                                    <SparkTelephonyDetailsForm
                                        loadTelephonyDeployments={loadTelephonyDeployments}
                                        selectedTelephonyDeployment={telephonyDeployment}
                                        isLoadingTelephonyDeployments={isLoadingTelephonyDeployments}
                                        handleSubmitTelephonyForm={handleSubmitTelephonyForm}
                                        getVoice={getVoice}
                                    />
                                </ContentCollapsingSection>
                            ))
                        ) :
                            (
                                <EmptyView
                                    imageStyle={emptyViewImageStyle}
                                    title="We couldn't find any telephony deployments for this assistant."
                                    description="Configure a phone number to enable voice features."
                                />
                            )}
                </SettingsCard>
            </ScrollablePanelBody>
        )
    };

    const renderModuleFormBody = () => {
        const { isLoading } = props.appliedAppTemplateContainer.state;
        const currentAppliedTemplate = getCurrentAppliedTemplate();
        const defaultValues = getDefaultTemplateValues();
        if (currentAppliedTemplate != null && (currentAppliedTemplate?.templateFormFieldValues == null || currentAppliedTemplate?.templateFormFieldValues?.length === 0))
            currentAppliedTemplate.templateFormFieldValues = defaultValues;

        if (isLoading)
            return <Loader />

        if (!currentSection && !telephonyDeploymentSection && !autoGenerateFormResponsesSection && !isFinishSelected) {
            return <EmptyView
                imageStyle={emptyViewImageStyle}
                title="It's quiet in here!"
                description="It doesn't look like your assistant has any content. Reach out to your administrator to get set up."
            />
        }

        if (currentSection)
            return <UpdateAppliedAppTemplateForm
                calculateIncompleteSections={calculateIncompleteSections}
                importedFormFields={importedFormFields}
                setImportedFormFields={setImportedFormFields}
                currentSectionIndex={getCurrentSectionIndex()}
                history={props.history}
                applicationId={props.applicationId}
                appContainer={props.appContainer}
                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                appliedAppValues={getCurrentAppliedTemplate()?.templateFormFieldValues ?? []}
                appliedAppTemplate={getCurrentAppliedTemplate()}
                templateSection={currentSection.section}
                insertDynamicFormSection={handleInsertDynamicFormSection}
                onNext={handleNextSection}
                onPrevious={handlePreviousSection}
            />

        if (telephonyDeploymentSection) {
            return <SparkTelephonyDeploymentForm
                getVoiceString={getVoiceString}
                incompleteSections={incompleteSections}
                setIncompleteSections={setIncompleteSections}
                setAppendedSections={setAppendedSections}
                appendedSections={appendedSections}
                telephonyDeploymentSection={telephonyDeploymentSection}
                voiceOptions={voiceOptions}
                handlePreviousSection={handlePreviousSection}
                handleNextSection={handleNextSection}
                createTelephonyDeployment={telephonyDeploymentContainer.createTelephonyDeployment}
                applicationId={props.applicationId}
                environmentId={props.applicationEnvironmentsContainer.state?.environments[0]?.id}
                clearTelephonyDeploymentErrors={telephonyDeploymentContainer.clearErrors}
                setPageErrorText={setPageErrorText}
                phoneNumberDeploymentErrorText={phoneNumberDeploymentErrorText}
                setPhoneNumberDeploymentErrorText={setPhoneNumberDeploymentErrorText}
                getTabs={getTabs}
                setTabs={setTabs}
                loadTelephonyDeployments={loadTelephonyDeployments}
                handleRemoveSection={handleRemoveSection}
                removeSectionFromIncompleteSections={removeSectionFromIncompleteSections}
            />
        }

        if (autoGenerateFormResponsesSection) {
            return <AutoGenerateFormFieldResponsesForm
                applicationId={props.applicationId}
                handlePreviousSection={handlePreviousSection}
                handleNextSection={handleNextSection}
                mediaContainer={props.mediaContainer}
                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                appendedSections={appendedSections}
                setAppendedSections={setAppendedSections}
                incompleteSections={incompleteSections}
                setIncompleteSections={setIncompleteSections}
                autoGenerateFormResponsesSection={autoGenerateFormResponsesSection}
                handleRemoveSection={handleRemoveSection}
                removeSectionFromIncompleteSections={removeSectionFromIncompleteSections}
                setIsLoading={(value) => setIsLoadingAutoGenerateAnswer(value)}
                isLoading={isLoadingAutoGenerateAnswer}
            />
        }

        if (isFinishSelected) {
            return <AppliedAppTemplateReviewForm
                history={props.history}
                applicationId={props.applicationId}
                appContainer={props.appContainer}
                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                appliedTemplates={props.appliedAppTemplateContainer.state.appliedTemplates}
                incompleteSections={incompleteSections}
                afterSubmit={startListeningForTraining}
                applicationEnvironmentsContainer={props.applicationEnvironmentsContainer}
                applicationType={applicationType}
                phoneNumberDeploymentErrorText={phoneNumberDeploymentErrorText}
                clearTelephonyDeploymentErrors={telephonyDeploymentContainer.clearErrors}
                loadTelephonyDeployments={loadTelephonyDeployments}
                isLoadingTelephonyDeployments={isLoadingTelephonyDeployments}
                calculateIncompleteSections={calculateIncompleteSections}
            />
        } else {
            return null;
        };

    };

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = "/v/apps/" + props.applicationId;
        switch (index) {
            case 0:
                props.history.push(urlStart)
                break;
            case 1:
                props.history.push(urlStart + "/voice")
                break;
            case 2:
                props.history.push(urlStart + "/analytics")
                break;
            case 3:
                props.history.push(urlStart + "/menu")
                break;
        }
    };

    const getSelectedIndex = () => {
        const url = props.history.location.pathname;
        if (url.includes("/voice")) {
            return 1;
        } else if (url.includes("/analytics")) {
            return 2;
        } else if (url.includes("/menu")) {
            return 3;
        }
        return 0;
    };

    const renderFormTabPanel = () => {
        return (
            <TabPanel>
                <PanelContainer className={containerStyle}>
                    {(currentSection || telephonyDeploymentSection || autoGenerateFormResponsesSection || isFinishSelected) &&
                        <MenuWrapper className={isLeftExpanded ? "expanded" : "collapsed"}>
                            <div className={"fixed-wrapper"}>
                                <CollapsingPanel
                                    isCollapsed={!isLeftExpanded}
                                    collapsedView={
                                        <CollapsedMenuPanel
                                            onExpand={(e) => handleToggleExpandLeft(e, true)}
                                            expandedViewRender={renderMenu}
                                        />
                                    }
                                    expandedView={
                                        <ExpandedMenuPanel
                                            onCollapse={(e) => handleToggleExpandLeft(e, false)}
                                            expandedViewRender={renderMenu}
                                            minimizedDrawer={isMobile}
                                        />
                                    } />
                            </div>
                        </MenuWrapper>
                    }
                    <PanelContainer className={isLeftExpanded ? expandedMenuFormBodyStyle : collapsedMenuFormBodyStyle}>
                        <div className="form-wrapper">
                            {renderModuleFormBody()}
                        </div>
                    </PanelContainer>
                </PanelContainer>
                <PageError errors={props.appliedAppTemplateContainer?.state?.errors} onClear={() => props.appliedAppTemplateContainer?.clearErrors()} />
                <PageError errors={fileImportErrors} onClear={() => setFileImportErrors([])} />
            </TabPanel>

        );
    }

    const renderMenuTab = () => {
        return (<TabPanel>
            {menuSyncing ? <EditControlPanel onClick={(e) => e.stopPropagation()} /> : <></>}
            {menuSyncing ?
                <StatusIndicator
                    state={"loading"}
                    failedMessage=''
                    loadingMessage='Training live assistant with updated menu'
                    successMessage=''
                    isClosable={false}
                    onClose={() => { }}
                    onAcceptFailure={() => { }}
                />
                :
                <></>
            }
            <ScrollablePanelBody className={menuSyncing ? blurComponent : ""}>
                <Route path="/v/apps/:applicationId/menu/item/:id" exact
                    render={() =>
                        <ApplicationMenuItemManager
                            application={props.appContainer.state.currentApp}
                            history={props.history} />
                    } />
                <Route path="/v/apps/:applicationId/menu" exact
                    render={() =>
                        <MenuManagement
                            application={props.appContainer.state.currentApp}
                            history={props.history}
                            selectedCategoryIndex={selectedMenuCategoryIndex}
                            setSelectedCategoryIndex={setSelectedMenuCategoryIndex}
                        />
                    } />

            </ScrollablePanelBody>
        </TabPanel>)
    }

    if (isLoadingApplicationType || !loaded) return <Loader />;
    return (
        <FullPagePanel className={containerStyle}>
            {!(applicationType === "common") ?
                <FullPageTabbedPanel tabs={tabs}
                    onTabSelected={handleTabSelection}
                    selectedIndex={selectedTab}
                    leftRender={() => {
                        const path = `${props?.history?.location?.pathname ?? ""}`;
                        if (path.toLowerCase()?.includes("menu/item"))
                            return undefined;
                        return <>
                            <BackButton onClick={props.history.location.pathname === `/v/apps/${props.applicationId}` ? handleClose : () => props.history.push(`/`)} />
                        </>
                    }}
                    titleRender={() => <HeaderTitleView text={props.appContainer?.state?.currentApp?.name} />
                    }
                    rightRender={() =>
                        <>
                            <HeaderSeparator />
                            <ProfileMenu />
                        </>
                    }>
                    {renderFormTabPanel()}
                    <TabPanel>
                        <ScrollablePanelBody>
                            {renderTelephonyDetails()}
                        </ScrollablePanelBody>
                    </TabPanel>
                    <TabPanel>
                        <ApplicationAnalytics
                            application={props.appContainer.state.currentApp}
                            facetContainer={props.facetContainer}
                            history={props.history}
                        />
                    </TabPanel>
                    {renderMenuTab()}
                </FullPageTabbedPanel>
                :
                <FullPageTabbedPanel tabs={[{
                    title: "Form",
                    icon: formIcon,
                    selectedIcon: formIcon
                }]}
                    onTabSelected={handleTabSelection}
                    selectedIndex={selectedTab}
                    leftRender={() => {
                        const path = `${props?.history?.location?.pathname ?? ""}`;
                        if (path.toLowerCase()?.includes("menu/item"))
                            return undefined;
                        return <>
                            <BackButton onClick={props.history.location.pathname === `/v/apps/${props.applicationId}` ? handleClose : () => props.history.push(`/`)} />
                        </>

                    }}
                    titleRender={() => <HeaderTitleView text={props.appContainer?.state?.currentApp?.name} />
                    }
                    rightRender={() =>
                        <>
                            <HeaderSeparator />
                            <ProfileMenu />
                        </>
                    }>
                    {renderFormTabPanel()}
                </FullPageTabbedPanel>
            }
            {
                closeModalIsOpen &&
                <StyledConfirmationDialog
                    title={`Confirm Close`}
                    deleteText="Yes, Close"
                    onClose={toggleCloseModal}
                    onConfirm={() => props.history.push(`/`)}>
                    <WarningConfirmation
                        text={`Are you sure you want to close this form? Any unsaved changes will be lost.`}
                    />
                </StyledConfirmationDialog>
            }
        </FullPagePanel>
    );
}

const StyledConfirmationDialog = styled(ConfirmationDialog)``;

const collapsedSection = css`
    margin: 0 -32px;
    *.card-title-container {
        padding: 24px 24px 24px 40px;
    }
    ${breakpoint_small} {
        margin: 0 -16px;
    }
`;

const emptyViewImageStyle = css`
    ${breakpoint_small} {
        height: 350px;
        width: 350px;
    }
`;

const stepHeaderWrapper = css`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const containerStyle = css`
    background: ${color_variants_main_bg};
    .form-wrapper {
        flex: 1;
    }
    .empty-indicator {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .page-indicator-container {
        background: ${color_shades_lighter};
        border: 1px solid ${color_shades_dark};
        box-sizing: border-box;
        border-radius: 4px;
        margin: 0 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
    }
`;

const collapsedMenuFormBodyStyle = css`
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    .scrollable-body {       
        padding-bottom: 56px;
    }
`;

const expandedMenuFormBodyStyle = css`
    border-right: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    .scrollable-body {       
        padding-bottom: 56px;
    }
    ${breakpoint_small} {
        display: none;
    }
`;

const paddedBody = css`
    padding: 32px;
    margin-bottom: 48px;
`;

const MenuWrapper = styled.div`
    z-index: 2;
    bottom: 0;
    position: relative;
    &.collapsed {
        width: 80px;
        .fixed-wrapper {
            width: 80px;
        }
    }
    &.expanded {
        width: 288px; 
        .fixed-wrapper {
            width: 288px;
        }
    }
    .fixed-wrapper {
        position: fixed;
        bottom: 0;
        height: calc(100vh - 80px);
        background: white;
        border-right: 1px solid ${color_shades_dark};
        box-sizing: border-box;
    }
    ${breakpoint_small} {
        &.collapsed {
            width: 0;
            .fixed-wrapper {
                width: 0;
            }
        }
        &.expanded {
            width: 288px; 
            .fixed-wrapper {
                width: 100px;
            }
        }
        .fixed-wrapper {
            position: fixed;
            bottom: 0;
            height: calc(100vh - 80px);
            background: white;
            border-right: 1px solid ${color_shades_dark};
            box-sizing: border-box;
        }
    }
`;

const EditControlPanel = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: blur(2px);
`;

const blurComponent = css`
    filter: blur(2px);
`;

const nextSearchBtnStyle = css`
    margin: 0px;
    padding: 0px;
    position: absolute;
    height: 100%;
    top: 58%;
    right: 3%;
    transform: translate(-50%, -50%);
    font-size: 12px;

`;
const downArrowIconStyle = css`
    width: 18px;
`;

const SearchFieldButtonWrapper = styled.div`
    position: relative;
`;

const StyledSearchField = styled(SearchField)`   
    margin: auto;
    width: 88%;
    margin-bottom: 10px;
`;

export default ApplicationManagerContent;