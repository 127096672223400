import * as voicifyApi from '../../api';
import CustomRequestModel from '../../models/features/api/CustomRequests/CustomRequestModel';
import NewCustomRequestRequest from '../../models/features/api/CustomRequests/NewCustomRequestRequest';
import BulkUpdateCustomRequestRequest from '../../models/features/api/CustomRequests/BulkUpdateCustomRequestRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import { CustomRequestsFeatureTypeId } from '../../constants/featureTypeIds';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { CustomRequestContentFormData } from '../../scenes/formScenes/CustomRequestForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';
import IResult from '../../models/result/IResult';

interface CustomRequestState extends ContentItemState<CustomRequestModel> {
}

export default class CustomRequestContainer extends GenericContentItemContainer<CustomRequestState, CustomRequestModel, NewCustomRequestRequest, BulkUpdateCustomRequestRequest, CustomRequestContentFormData> {
       
    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isPublishing: false,
        isSavingStub: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }

    mapModelToGenericContentItemModel = (model: CustomRequestModel) => ({
        ...model,
        hasAudio: model.responses.some(a => a.audio != null),
        hasVideo: model.responses.some(a => a.video != null),
        hasImage: model.responses.some(a => a.largeImage != null),
        hasBackgroundImage: model.responses.some(a => a.backgroundImage != null),
        featureTypeId: CustomRequestsFeatureTypeId
    })

    mapFormDataToModel = (originalModel: CustomRequestModel, formData: CustomRequestContentFormData) => ({
        ...originalModel,
        webhookChainingType: formData.webhookChainingType,
        id: originalModel.id,
        title: formData.title,
        requiresParent: formData.requiresParent,
        isLive: formData.isLive,
        requestTypes: formData.requestTypes,
        requestNames: formData.requestNames,
        platformFilter: formData.platformFilter,
        languages: formData.languages,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,
        alwaysOpen: formData.alwaysOpen,
    });

    featureTypeId = CustomRequestsFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getCustomRequestsForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // not implemented
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getCustomRequestsForAppFeature(applicationFeatureId);
    createPromise = (model: NewCustomRequestRequest) => voicifyApi.createCustomRequest(model);
    createStubPromise = (model: NewCustomRequestRequest) => voicifyApi.createCustomRequestStub(model);
    createFromModelPromise = (model: CustomRequestModel) => voicifyApi.createFullCustomRequest(model);
    updatePromise = (contentItemId: string, model: BulkUpdateCustomRequestRequest) => voicifyApi.bulkUpdateCustomRequest(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: BulkUpdateCustomRequestRequest) => voicifyApi.bulkUpdateCustomRequestStub(contentItemId, model);
    updateFromModelPromise = (model: CustomRequestModel) => voicifyApi.updateFullCustomRequest(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteCustomRequest(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleCustomRequestLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleCustomRequestSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyCustomRequest(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findCustomRequest(contentItemId);    
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getCustomRequestPublishHistory(contentItemId, environmentId, skip, take);


    getWebhooksPromise = (contentItemId: string) => voicifyApi.getCustomRequestWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeCustomRequestWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateCustomRequestWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addCustomRequestWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateCustomRequestWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);

    getSimpleSampleFlows = (contentItem: CustomRequestModel, application?: ApplicationModel) => [{
        requests: [
            { content: `[Custom Request]`, highlights: [], id: '1' },
        ],
        followUp: contentItem.responses[0]?.followUp,
        responses: contentItem.responses.map(a => ({
            content: a.content,
            highlights: [a.content],
            id: a.content
        })),
        requestTitle: "User Request",
        responseTitle: "Device Response"
    }]

}
